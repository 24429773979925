<template>
  <div class="px-10">
    <BaseButton
      color="none"
      class="!px-0 ring-0 outline-none focus:!ring-0 focus:!outline-none !text-xs max-h-md:!text-sm !pb-1 pt-5 -ms-2"
      @click="handleNext(event)">
      <p class="hover:text-white/80 flex items-center" :class="returnColor">
        <ChevronRight class="w-4" />
        חזרה
      </p>
    </BaseButton>
    <div class="relative flex flex-col gap-3 max-h-lg:pt-3">
      <MeltIceCream
        class="absolute -top-20 -end-5 scale-75 max-h-md:scale-100"/>
      <div
        class="flex text-right gap-2 flex flex-col rounded-xl p-3 max-h-md:p-5 bg-[#FFFFFF33]"
        :style="{ minHeight: `${height * 0.75}px` }">
        <h1
          class="text-base max-h-md:text-lg max-h-lg:text-xl max-h-md:mb-3"
          :class="titleColor">
          {{ option.title }}
        </h1>
        <div
          class="prose text-xxs"
          :class="textColor"
          v-html="option.subtitle"></div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { useState } from '@/apps/slideshowGame/useState';
import MeltIceCream from '@/assets/melting-ice-cream.svg';
import BaseButton from '@/components/Button/BaseButton.vue';
import { ChevronRight } from 'lucide-vue-next';
import { useWindowSize } from '@vueuse/core';
defineProps({
  option: {
    type: Object,
    required: true,
  },
  event: {
    type: String,
    default: 'BACK',
    required: true,
  },
  textColor: {
    type: String,
    default: 'prose-p:text-black',
  },
  returnColor: {
    type: String,
    default: 'text-white',
  },
  titleColor: {
    type: String,
    default: 'text-[#FFD94E]',
  },
});

const { handleNext } = useState();
const { height } = useWindowSize();
</script>
