<template>
  <div class="flex flex-col max-h-lg:gap-3 items-center px-5 max-h-md:px-10">
    <div class="relative flex flex-col gap-3 max-h-md:pt-10">
      <MeltIceCream
        class="absolute -top-20 max-h-md:-top-10 -end-10 scale-75 max-h-md:scale-100"/>
      <div
        v-for="(text, index) in combinedText"
        :key="index"
        class="flex text-right gap-2 flex flex-col rounded-xl p-3 max-h-md:p-5 bg-[#FFFFFF33]">
        <h6 :class="titleColor">{{ text.title }}</h6>
        <p :class="textColor">{{ text.subtitle }}</p>
        <BaseButton
          color="none"
          class="bg-white/25 hover:bg-white/30 rounded w-fit ring-0 outline-none focus:!ring-0 focus:!outline-none"
          @click="handleNext(text.event)">
          <p :class="textColor">{{ userGenderText }}</p>
        </BaseButton>
      </div>
    </div>
    <div
      class="mt-3 max-h-md:mt-10 bg-orange-500 hover:contrast-125 cursor-pointer w-50 py-3 rounded-3xl text-white font-simplerBold"
      @click="handleNext(event)">
      {{ 'סיימתי לקרוא' }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useState } from '@/apps/slideshowGame/useState';
import { computedFinalCategoryCounts } from '@/apps/slideshowGame/useCalculation';
import { computed, ref, watchEffect } from 'vue';
import MeltIceCream from '@/assets/melting-ice-cream.svg';
import BaseButton from '@/components/Button/BaseButton.vue';
import { useReveal } from '@/apps/slideshow/useReveal';

const props = defineProps({
  options: {
    type: Object,
    required: true,
  },
  event: {
    type: String,
    required: true,
  },
  textColor: {
    type: String,
    default: 'text-white',
  },
  titleColor: {
    type: String,
    default: 'text-[#FFD94E]',
  },
});

const { user }: { user: any } = useReveal();

const userGenderText = computed(() =>
  user.value?.gender === 'male' ? 'לחץ להסבר >' : 'לחצי להסבר >',
);

const { handleNext } = useState();
const combinedText = ref();

const getText = (options, type) => {
  const { Head, Belly, Decision, Deliberation } =
    computedFinalCategoryCounts.value;

  if (type === 'head-belly') {
    return Head >= 3 && Belly < 3
      ? options.find(option => option.type === 'head')
      : Head <= 2 && Belly >= 4
        ? options.find(option => option.type === 'belly')
        : options.find(option => option.type === 'middle');
  } else if (type === 'decision-deliberation') {
    return Decision >= 7
      ? options.find(option => option.type === 'decision')
      : Decision < 4 && Deliberation >= 5
        ? options.find(option => option.type === 'deliberation')
        : options.find(option => option.type === 'middle');
  }
};

const setTexts = () => {
  const {
    'head-belly': headBellyOptions,
    'decision-deliberation': decisionDeliberationOptions,
  } = props.options;
  const headBellyText = getText(headBellyOptions, 'head-belly');
  const decisionDeliberationText = getText(
    decisionDeliberationOptions,
    'decision-deliberation',
  );
  combinedText.value = [
    {
      title: headBellyText.title,
      subtitle: headBellyText.subtitle,
      event: headBellyText.event,
    },
    {
      title: decisionDeliberationText.title,
      subtitle: decisionDeliberationText.subtitle,
      event: decisionDeliberationText.event,
    },
  ];
};

watchEffect(() => {
  setTexts();
});
</script>
