export const slide11 = {
  on: {
    CLICK_NEXT: 'slide12',
  },
  meta: {
    id: 'slide11',
    section: '65f0218666c26d474c6cd587',
    event: 'CLICK_NEXT',
    display: {
      backgroundColor: '',
      backgroundGradient: 'linear-gradient(119deg, #FDE8EB 0%, #FFCD14 100%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'image',
        type: 'content',
        content: {
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1709802181/Decision%20making%20game/girls/image_9_ni2ol7.png',
          height: '180px',
          width: '230px',
        },
      },
      {
        name: 'base_text',
        type: 'content',
        content: {
          text: 'איזה מזל שבחרתי בפארק. בדיוק\n בדרך הביתה ראיתי שלט חוצות על\n אירועים של העירייה בקיץ בפארק.\n יהיו מלא לקוחות!!!',
          footer: 'יאללה להתלבטות הבאה...',
        },
      },
    ],
  },
};

export default slide11;
