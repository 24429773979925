import ActionType from '@/apps/slideshowGame/ActionType';
import { assign } from 'xstate';

export const slide45 = {
  id: 'slide45_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        1000: { target: '#slide45_fragments.fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'image',
        type: 'content',
        content: {
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721804070/Decision%20making%20game/boy/lu8eank8cfoguuwuelly.svg',
          width: '75%',
          placement: 'start',
        },
      },
    },
    fragment2: {
      after: {
        1000: { target: '#slide45_fragments.fragment3' },
      },
      meta: {
        id: 'fragment2',
        name: 'choice',
        content: {
          event: ActionType.SYSTEMATIC,
          title: 'אחי תעשה סקר שוק ותבדוק. אולי באמת יש שוק לארטיקים טבעיים.',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721803388/Decision%20making%20game/boy/uqfri8djr7emeaxsvqi4.svg',
        },
      },
    },
    fragment3: {
      after: {
        1000: { target: '#slide45_fragments.fragment4' },
      },
      meta: {
        id: 'fragment3',
        name: 'choice',
        content: {
          event: ActionType.EMOTION,
          title:
            'למה לך להסתבך? תצא בסדר עם ההורים. אל תעצבן אותם ותעבור לטבעיים בכל מקרה.',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721803342/Decision%20making%20game/boy/a2tihpimudm8d3fv56hi.svg',
        },
      },
    },
    fragment4: {
      after: {
        1000: { target: '#slide45_fragments.fragment5' },
      },
      meta: {
        id: 'fragment4',
        name: 'choice',
        content: {
          event: ActionType.IMPULSIVE,
          title:
            ' נראה לך? מה אתה עובד אצלם? תישאר בדיוק כמו שאתה ושאף אחד לא יגיד לך מה לעשות.',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721803421/Decision%20making%20game/boy/cjvx3tznhons9ufe5jyg.svg',
        },
      },
    },
    fragment5: {
      on: {
        [ActionType.SYSTEMATIC]: {
          target: '#slides.slide46',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 7220,
            };
          }),
        },
        [ActionType.EMOTION]: {
          target: '#slides.slide47',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 5230,
            };
          }),
        },
        [ActionType.IMPULSIVE]: {
          target: '#slides.slide48',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 6340,
            };
          }),
        },
        [ActionType.RATIONAL]: {
          target: '#slides.slide49',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 6860,
            };
          }),
        },
      },
      meta: {
        id: 'fragment5',
        name: 'choice',
        content: {
          event: ActionType.RATIONAL,
          title:
            'רגע! בלי להתלהם. דבר עם נציגות של ההורים ותנסה להבין אותם קצת יותר.',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721803915/Decision%20making%20game/boy/zosq0z6nyayezpsvtpiz.svg',
        },
      },
    },
  },
  meta: {
    id: 'slide45',
    section: '65f6fd56ea41daf4eb6fbd34',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(46.92deg, #F9ED32 -21.38%, #FBB040 180.02%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-dark',
          title: 'תראו את המייל שקיבלתי...',
          subtitle: 'מה כדאי לי לענות? תלחץ על התגובה שאתה היית בוחר',
        },
      },
    ],
    settings: {
      container: {
        backgroundColor: '#F5F5F5',
        display: 'end',
        heightPercentage: 0.77,
      },
    },
  },
};
export default slide45;
