export const slide42 = {
  on: {
    CLICK_NEXT: 'slide44',
  },
  meta: {
    id: 'slide42',
    section: '65f6bd2b260611eab1b11adb',
    event: 'CLICK_NEXT',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(46.92deg, #F9ED32 -21.38%, #FBB040 180.02%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'image',
        type: 'content',
        content: {
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1710754494/Decision%20making%20game/girls/Post_Skilly_1_gh1scv.svg',
        },
      },
    ],
  },
};
export default slide42;
