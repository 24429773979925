import ActionType from '@/apps/slideshowGame/ActionType';

export const slide44 = {
  id: 'slide44_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        1000: { target: '#slide44_fragments.fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'message',
        content: {
          background: '#FF9502',
          color: 'text-white',
          text: 'הספק של הארטיקים כתב לי:\n יש לך מזל כנראה. הלך לי המקפיא. אני חייב להיפטר מיידית מהסחורה. אם תקנה את כל הארטיקים אני עושה לך 50% הנחה... \n מה כדאי לי לעשות? ',
        },
      },
    },
    fragment2: {
      after: {
        1000: { target: '#slide44_fragments.fragment3' },
      },
      meta: {
        id: 'fragment2',
        name: 'choice',
        content: {
          event: ActionType.EMOTION,
          title:
            'רגע רגע... מה פתאום!? זו התחייבות מאוד גדולה לקנות את כל הכמות. ',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721803421/Decision%20making%20game/boy/cjvx3tznhons9ufe5jyg.svg',
        },
      },
    },
    fragment3: {
      after: {
        1000: { target: '#slide44_fragments.fragment4' },
      },
      meta: {
        id: 'fragment3',
        name: 'choice',
        content: {
          event: ActionType.IMPULSIVE,
          title: 'לקנות ומהר. זו הזדמנות מעולה לקנות בחצי מחיר. רוץ על זה...',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721803388/Decision%20making%20game/boy/uqfri8djr7emeaxsvqi4.svg',
        },
      },
    },
    fragment4: {
      on: {
        [ActionType.EMOTION]: { target: '#slides.slide58' },
        [ActionType.IMPULSIVE]: { target: '#slides.slide59' },
        [ActionType.SYSTEMATIC]: { target: '#slides.slide60' },
      },
      meta: {
        id: 'fragment4',
        name: 'choice',
        content: {
          event: ActionType.SYSTEMATIC,
          title: ' מה אתם קופצים? חכו רגע. תבדוק בדיוק על כמה ארטיקים מדובר.',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721803342/Decision%20making%20game/boy/a2tihpimudm8d3fv56hi.svg',
        },
      },
    },
  },
  meta: {
    id: 'slide44',
    section: '65f6bff57989236ea9a3a207',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(46.92deg, #F9ED32 -21.38%, #FBB040 180.02%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-dark',
          title: 'היי, אני חייב שתעזרו לי...',
          subtitle:
            'לא מאמין איזו הזדמנות נפלה עליי. אבל צריך להחליט\n מהר. תלחצו על התגובה שלדעתכם אני צריך לבחור ',
        },
      },
    ],
    settings: {
      container: {
        backgroundColor: '#F5F5F5',
        display: 'end',
        heightPercentage: 0.7,
        header: {
          backgroundHeader: '#FFFFFF',
          title: 'הקומונה של מיכאל',
          type: 'chat',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721803267/Decision%20making%20game/boy/hnracj4xhkrgkli1zoiw.svg',
        },
      },
    },
  },
};

export default slide44;
