<template>
  <div class="flex w-full object-contain" :class="placementClasses[placement]">
    <img :style="{ width, height }" :alt="alt" :data-src="src" />
  </div>
</template>

<script setup lang="ts">
type PlacementType = 'center' | 'start' | 'end';

const placementClasses: Record<PlacementType, string> = {
  center: 'justify-center',
  start: 'justify-start',
  end: 'justify-end',
};

interface IProps {
  src?: string;
  placement?: PlacementType;
  width?: string;
  height?: string;
  alt?: string;
}

withDefaults(defineProps<IProps>(), {
  src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1698918297/Plane_ypcwlc.svg',
  placement: 'center',
  width: '90%',
  height: '100%',
  alt: '',
});
</script>
