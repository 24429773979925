export const slide1 = {
  on: {
    CLICK_NEXT: 'slide2',
  },
  meta: {
    id: 'slide1',
    display: {
      backgroundColor: '',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'welcome_slide',
        type: 'content',
        content: {
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721733485/Decision%20making%20game/boy/opaggkfls8cmlz4tezu2.svg',
          title: 'משחק קבלת החלטות!',
          subtitle:
            'כל יום ובכל שעה אנחנו מקבלים הרבה החלטות. חלקן\n החלטות גדולות ומשמעותיות, וחלקן החלטות יומיומיות\n שאותן אנחנו מבצעים באופן אוטומטי כמעט. עצרתם פעם \nלחשוב על זה? איך אתם מחליטים? מה האפשרויות\n בכל מצב שמתוכן אתם בוחרים? ואיך אתם בוחרים\n ביניהן? בקיצור, האם אתם יודעים לומר משהו על סגנון\n קבלת ההחלטות שלכם? ',
          event: 'CLICK_NEXT',
        },
      },
    ],
  },
};

export default slide1;
