import ActionType from '@/apps/slideshowGame/ActionType';
import BigStar from '@/assets/decisionMakingGame/big-star.svg';
import SmallStar from '@/assets/decisionMakingGame/small-star.svg';

const popupFadeup = {
  name: 'popup-fadeup',
  secondary: 'fade-in',
  initialValues: (parent, child) => {
    const parentBoundries = parent?.getBoundingClientRect();
    const childBoundries = child?.getBoundingClientRect();

    return {
      y:
        parent.clientHeight -
        (childBoundries?.y - parentBoundries?.y + childBoundries.height),
      x: childBoundries?.width,
    };
  },
  keyframes: value => `
  @keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes inner {
  0% {
    border-radius: 8px;
    background: white;
    transform: scale(1.15);
    bottom: 10%;
  }
  20% {
  bottom: 10%;
  }
  40% {
    border-radius: 8px;
    margin-right: 0px;
    margin-left: 0px;
  }
  85% {
  transform: scaleY(1) scaleX(0.9);
    padding-right: 0px;
    padding-top: 4px;
    border-radius: 25px;
  }
  95% {
    padding-top: 0px;
    border-radius: 990px;
  }
  100% {
    margin-right: 0px;
    margin-left: 0px;
  transform: scaleX(0.9) scaleY(1);
    border-radius: 990px;
    padding-top: 4px;
    background: white;
     bottom: ${value.y}px;
  }
}

@keyframes extra {
  0% {
    visibility: visible;
    max-height: 200px;
    overflow: hidden;
    opacity: 1;
  }
  85% {
    max-height: 18px;
    padding-right: 0px;
    padding-left: 0px;
    padding-top:0px;
  }
  95% {
    overflow: hidden;
    max-height: 18px;
    padding-right: 14px;
    padding-left: 14px;
    padding-top:0px;
    opacity: 0.2;
  }
  100% {
    overflow: hidden;
    max-height: 18px;
    padding-right: 14px;
    padding-left: 14px;
    padding-top:0px;
    opacity: 0.2;
  }
}

@keyframes icon {
  0% {
    transform: scale(3) translate(0, 0);
    opacity: 1;
  }
  85% {
    transform: translate(${value.x / 2 - 12}px, 24px);
  }
  95% {
    transform: scale(0.98) translate(${value.x / 2 - 12}px, 24px);
    opacity: 0.1;
  }
  100% {

    opacity: 0.1;
  }
}

.vertical-block > div > button {
    position: absolute;
    left: 0;
    bottom: 10%;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    border-radius: 0.375rem;
    transform: scale(1.15);
    background: white;
}
.vertical-block .icon {
    margin-top: -1rem;
    margin-left: auto;
    margin-right: auto;
    transform: scale(3);
    text-align: center;
    left: 0px;
}

.vertical-block .title {
  display: none;
}
.vertical-block .extra {
  display: block;
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
  margin-left: 1.5rem;
  margin-right: 1.5rem;
  text-align: justify;
}

.current-fragment.fade-in {
  opacity: 0;
  animation: fadeIn 1s ease-in-out forwards;
}

.start-animation.current-fragment.popup-fadeup > div > button {
    pointer-events: none;
    animation: inner 3s ease-in-out forwards;
  }

.start-animation.current-fragment.popup-fadeup .extra {
    animation: extra 3s ease-in-out forwards;
  }

.start-animation.current-fragment.popup-fadeup .icon {
    animation: icon 3s ease-in-out forwards;
  }
          `,
  startAddClasses: ['vertical-block'],
  endRemoveClasses: ['vertical-block'],
  animationSeconds: 2500,
  events: [],
};

export const slide3 = {
  id: 'slide3_fragments',
  initial: 'fragment0',
  on: {
    GO_TO_FRAGMENT2: { target: '#slide3_fragments.fragment2' },
    GO_TO_FRAGMENT3: { target: '#slide3_fragments.fragment3' },
    GO_TO_FRAGMENT4: { target: '#slide3_fragments.fragment4' },
  },
  states: {
    fragment0: {
      after: {
        900: { target: '#slide3_fragments.fragment1' },
      },
      meta: {
        id: 'fragment0',
      },
    },
    fragment1: {
      meta: {
        id: 'fragment1',
        name: 'choice',
        animation: { ...popupFadeup, events: ['GO_TO_FRAGMENT2'] },
        content: {
          event: ActionType.EMOTION,
          title: '<b>לב לנויה</b> חברה שלי',
          id: '65d4749f26244cd8a61440f1',
          icon: '❤️',
          extra:
            '<b>לב לנויה</b>, אני מתה עליה. היא חברה ממש טובה שלי. אמנם בדיוק כמוני אין לה שום נסיון בעסקים, אבל אני בטוחה שיהיה לנו כיף כחברות וזאת תהיה אחלה חוויה מגבשת לקיץ.',
        },
      },
    },
    fragment2: {
      meta: {
        id: 'fragment2',
        name: 'choice',
        animation: { ...popupFadeup, events: ['GO_TO_FRAGMENT3'] },
        content: {
          event: ActionType.RATIONAL,
          title: '<b>מוח ליעל</b> יש לה ניסיון במכירות',
          id: '65d474b3e5c46f97aa4c7919',
          icon: '🧠',
          extra:
            '<b>מוח ליעל</b>  שלא כמו נויה, יש לה ניסיון במכירות ובקיץ האחרון היא עבדה בקיוסק בשכונה. היא אמנם לא חברה שלי, אבל אולי כדאי להפריד בין ביזנס לפלזר ולקחת מישהי רצינית שיקפיץ את העסק קדימה. מי אמר שהכל צריך להיות כיף בחיים?',
        },
      },
    },
    fragment3: {
      on: {
        SHOW_NEXT: { target: '#slide3_fragments.fragment4' },
      },
      meta: {
        id: 'fragment3',
        name: 'choice',
        animation: { ...popupFadeup, events: ['GO_TO_FRAGMENT4'] },
        content: {
          event: ActionType.APPROVAL,
          title: '<b>משקפיים לאבא ואמא שלי</b> להתייעץ',
          id: '65d474af0df1d640a3b377e0',
          icon: '🥸',
          extra:
            '<b>משקפיים לאבא ואמא שלי</b>. מה הם קשורים? חשבתי אולי להתייעץ איתם בתור מי שמכירים אותי הכי טוב, ואני סומכת על שיקול דעתם.',
        },
      },
    },
    fragment4: {
      on: {
        [ActionType.EMOTION]: { target: '#slides.slide5' },
        [ActionType.RATIONAL]: { target: '#slides.slide7' },
        [ActionType.PROCRASTINATION]: { target: '#slides.slide4' },
        [ActionType.APPROVAL]: { target: '#slides.slide6' },
      },
      meta: {
        id: 'fragment4',
        name: 'choice',
        animation: { ...popupFadeup },
        content: {
          event: ActionType.PROCRASTINATION,
          title: '<b>מיטה אני צריכה לישון על זה...</b>',
          id: '65d474b77acd8609b3a36bdd',
          icon: '🛏',
          extra:
            '<b>מיטה אולי אני צריכה לישון על זה.</b> לחכות. לבדוק עוד אופציות. למשל חשבתי לפנות גם למאיה ויהל. ואולי צריך קצת לחכות עם ההחלטה.',
        },
      },
    },
  },
  meta: {
    id: 'slide3',
    section: '65f021098fbf2923bbe4a361',
    display: {
      backgroundColor: '#E161F5',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-white',
          title: 'בחרי עם מי כדאי לי\n להקים את הדוכן?',
          subtitle:
            'תבחרו אימוג\'י לפי מה שאתם ממליצים לי לעשות.\n לפי מה שתמליצו אתקדם...',
        },
      },
    ],
    elements: [
      {
        src: BigStar,
        x: '92%',
        y: '-28%',
        zIndex: 100,
      },
      {
        src: SmallStar,
        x: '-30px',
        y: '-140px',
        zIndex: 100,
      },
    ],
    settings: {
      container: {
        overflow: true,
        backgroundColor: '#FFFFFF',
        display: 'end',
        heightPercentage: 0.65,
        header: {
          backgroundHeader: '#FFCD14',
        },
      },
    },
  },
};

export default slide3;
