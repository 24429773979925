import ActionType from '@/apps/slideshowGame/ActionType';
import Girl from '@/assets/decisionMakingGame/girl.svg';

export const slide18 = {
  id: 'slide18_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        1000: { target: '#slide18_fragments.fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'choice',
        content: {
          event: ActionType.RATIONAL,
          title: 'אין מצב. אם תתחילי לחלק ארטיקים בחינם, העסק ייסגר.',
          subtitle:
            'שומעים? הילד נעלב והלך מאוכזב. עכשיו גם\n החברים שלו כועסים עליי והחליטו להחרים לנו את\n הדוכן ולהגיד ללקוחות שלנו לא לקנות. ',
          toSlide: 'slide19',
        },
      },
    },
    fragment2: {
      after: {
        1000: { target: '#slide18_fragments.fragment3' },
      },
      meta: {
        id: 'fragment2',
        name: 'choice',
        content: {
          event: ActionType.IMPULSIVE,
          title: ' יאללה בקטנה, תני לו. ארטיק אחד. לא יקרה כלום.',
        },
      },
    },
    fragment3: {
      after: {
        1000: { target: '#slide18_fragments.fragment4' },
      },
      meta: {
        id: 'fragment3',
        name: 'choice',
        content: {
          event: ActionType.EMOTION,
          title:
            'איזה מסכן. זה יהיה מעשה טוב לתת לו, שלא יתבייש שרק לו אין כסף.              ',
        },
      },
    },
    fragment4: {
      after: {
        1000: { target: '#slide18_fragments.fragment5' },
      },
      meta: {
        id: 'fragment4',
        name: 'choice',
        content: {
          event: ActionType.APPROVAL,
          title: 'תשאלי את השותפה. שהיא תחליט              ',
          subtitle:
            'שומעים? התייעצתי עם השותפה והיא הייתה\n נחרצת. לא נותנים בחינם. הילד התעצבן\n והלך מאוכזב. ועכשיו גם החברים שלו כועסים עליי\n והחליטו להחרים לנו את הדוכן ולהגיד ללקוחות\n שלנו לא לקנות.',
          toSlide: 'slide19',
        },
      },
    },
    fragment5: {
      after: {
        1000: { target: '#slide18_fragments.fragment6' },
      },
      meta: {
        id: 'fragment5',
        name: 'choice',
        content: {
          event: ActionType.SYSTEMATIC,
          title:
            'תתייעצי עם מוכרים מדוכנים אחרים באזור. בטח גם להם קרה משהו דומה.              ',
          subtitle:
            'שומעים? בזמן שהרמתי טלפון להתייעץ עם מוכרים \n אחרים שאני מכירה, הילד נעלב והלך מאוכזב. עכשיו \n גם החברים שלו כועסים עליי והחליטו להחרים לנו את\n הדוכן ולהגיד ללקוחות שלנו לא לקנות.',
          toSlide: 'slide19',
        },
      },
    },
    fragment6: {
      on: {
        [ActionType.PROCRASTINATION]: { target: '#slides.slide19' },
        [ActionType.SYSTEMATIC]: { target: '#slides.slide19' },
        [ActionType.RATIONAL]: { target: '#slides.slide19' },
        [ActionType.APPROVAL]: { target: '#slides.slide19' },
        [ActionType.EMOTION]: { target: '#slides.slide25' },
        [ActionType.IMPULSIVE]: { target: '#slides.slide25' },
      },
      meta: {
        id: 'fragment6',
        name: 'choice',
        content: {
          event: ActionType.PROCRASTINATION,
          title: 'תגידי לו שאת צריכה לחשוב על זה ושיחזור אחר כך.              ',
          subtitle:
            'שומעים? אמרתי לו שיתן לי לחשוב על זה,\n וההוא חיכה שאני אצא להפסקה ובא לשאול\n את השותפה שלי. היא כמובן הייתה הרבה יותר נחרצת.\n לא נותנים בחינם. עכשיו הילד נעלב והלך מאוכזב.\n עכשיו גם החברים שלו כועסים עליי והחליטו להחרים לנו את\n הדוכן ולהגיד ללקוחות שלנו לא לקנות.',
          toSlide: 'slide19',
        },
      },
    },
  },
  meta: {
    id: 'slide18',
    section: '65f021ea12035cd06c39224f',
    display: {
      backgroundColor: '#C83FFE',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [],
    elements: [
      {
        src: Girl,
        x: '65%',
        y: '-20%',
        zIndex: 100,
      },
    ],
    settings: {
      container: {
        backgroundColor: '#FFFFFF',
        borderRadius: '24px',
        marginTop: '45px',
        header: {
          backgroundHeader: '#252526',
          title: 'מה דעתך? \n מה לעשות? ',
        },
      },
    },
  },
};
export default slide18;
