export const slide34 = {
  on: {
    CLICK_NEXT: 'slide41',
  },
  meta: {
    id: 'slide34',
    section: '65f2cab0c06278d82f80b2e7',
    event: 'CLICK_NEXT',
    display: {
      backgroundColor: '',
      backgroundGradient: '',
      backgroundImage:
        'https://res.cloudinary.com/dcodkxpej/image/upload/v1710411961/Decision%20making%20game/girls/timothy-eberly-654Tcr0w568-unsplash_1_rpoxqe.svg.svg',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-white',
          title:
            'החברות יצאו מה זה פארשיות. עד\n שהסכמתי לסגור את הדוכן, הן\n פתאום רוצות ללכת לפיצה במקום\n ההופעה שממש רציתי לראות ',
        },
      },
    ],
  },
};
export default slide34;
