<template>
  <div
    class="w-full h-screen pb-5 flex flex-col justify-between bg-white rounded-3xl">
    <BaseImage
      :src="src"
      height="100%"
      width="100%"
      fit="contain"
      alt="welcome_image"
      density="compact"/>
    <div
      class="whitespace-pre-line font-anomaliaBold text-lg max-h-md:!text-2xl">
      {{ title }}
    </div>
    <div
      class="whitespace-pre-line leading-5 max-h-lg:leading-7 text-sm max-h-lg:text-md xl:pb-4">
      {{ subtitle }}
    </div>
    <div
      class="mx-auto w-fit bg-orange-500 hover:contrast-125 cursor-pointer px-10 py-3 xl:py-4 rounded-3xl text-white font-simplerBold text-sm xl:text-md mb-10"
      @click="handleNext(event)">
      {{ 'בואו נשחק ונגלה!!' }}
    </div>
  </div>
</template>

<script setup lang="ts">
import { useState } from '@/apps/slideshowGame/useState';
import BaseImage from '@/components/BaseImage/BaseImage.vue';

interface IProps {
  src?: string;
  title?: string;
  subtitle?: string;
  event: string;
}

withDefaults(defineProps<IProps>(), {
  title: '',
  subtitle: '',
  src: '',
  event: '',
});
const { handleNext } = useState();
</script>
