import ActionType from '@/apps/slideshowGame/ActionType';
import { assign } from 'xstate';

export const slide45 = {
  id: 'slide45_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        1000: { target: '#slide45_fragments.fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'image',
        type: 'content',
        content: {
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1718000163/Decision%20making%20game/girls/letter_kzmshc.svg',
          width: '75%',
          placement: 'start',
        },
      },
    },
    fragment2: {
      after: {
        1000: { target: '#slide45_fragments.fragment3' },
      },
      meta: {
        id: 'fragment2',
        name: 'choice',
        content: {
          event: ActionType.SYSTEMATIC,
          title: 'אחותי תעשי סקר שוק ותבדקי. אולי באמת יש שוק לארטיקים טבעיים.',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1710928357/Decision%20making%20game/girls/Ellipse_1_1_ykbmgd.svg',
        },
      },
    },
    fragment3: {
      after: {
        1000: { target: '#slide45_fragments.fragment4' },
      },
      meta: {
        id: 'fragment3',
        name: 'choice',
        content: {
          event: ActionType.EMOTION,
          title:
            'למה לך להסתבך? תצאי בסדר עם ההורים. אל תעצבני אותם ותעברי לטבעיים בכל מקרה.',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1711016795/Decision%20making%20game/girls/Ellipse_1_4_nkcwhl.svg',
        },
      },
    },
    fragment4: {
      after: {
        1000: { target: '#slide45_fragments.fragment5' },
      },
      meta: {
        id: 'fragment4',
        name: 'choice',
        content: {
          event: ActionType.IMPULSIVE,
          title:
            ' נראה לך? מה את עובדת אצלם? תישארי בדיוק כמו שאת ושאף אחד לא יגיד לך מה לעשות.',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1711016795/Decision%20making%20game/girls/Ellipse_1_5_zfjrm9.svg',
        },
      },
    },
    fragment5: {
      on: {
        [ActionType.SYSTEMATIC]: {
          target: '#slides.slide46',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 7220,
            };
          }),
        },
        [ActionType.EMOTION]: {
          target: '#slides.slide47',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 5230,
            };
          }),
        },
        [ActionType.IMPULSIVE]: {
          target: '#slides.slide48',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 6340,
            };
          }),
        },
        [ActionType.RATIONAL]: {
          target: '#slides.slide49',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 6860,
            };
          }),
        },
      },
      meta: {
        id: 'fragment5',
        name: 'choice',
        content: {
          event: ActionType.RATIONAL,
          title:
            ' רגע! בלי להתלהם. דברי עם נציגות של ההורים ותנסי להבין אותם קצת יותר.',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1711016795/Decision%20making%20game/girls/Ellipse_1_6_o5uijy.svg',
        },
      },
    },
  },
  meta: {
    id: 'slide45',
    section: '65f6fd56ea41daf4eb6fbd34',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(46.92deg, #F9ED32 -21.38%, #FBB040 180.02%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-dark',
          title: 'תראו את המייל שקיבלתי...',
          subtitle: ' מה כדאי לי לענות? תלחצי על התגובה שאת היית בוחרת',
        },
      },
    ],
    settings: {
      container: {
        backgroundColor: '#F5F5F5',
        display: 'end',
        heightPercentage: 0.77,
      },
    },
  },
};
export default slide45;
