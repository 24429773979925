import { endDay } from '@/apps/slideshowGame/useCalculation';

export const slide50 = {
  entry: ({ context }) => {
    endDay(context.metadata);
  },
  on: {
    CLICK_NEXT: 'slide69',
  },
  meta: {
    id: 'slide50',
    section: '65f80dc09fc1182456fc1a23',
    event: 'CLICK_NEXT',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(166.69deg, #BA60FE -14.98%, #D918FD 45.42%, #FBB2C8 109.36%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'image',
        type: 'content',
        content: {
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1710749944/Decision%20making%20game/girls/Vector_z4dvox.svg',
          height: '96px',
          width: '193px',
        },
      },
      {
        name: 'end_day_amount',
        type: 'content',
        content: {
          background:
            'linear-gradient(166.69deg, #BA60FE -14.98%, #D918FD 45.42%, #FBB2C8 109.36%)',
          prefix:
            'החיים הם כמו ארטיק - בסוף הוא נגמר ואת נשארת עם המקל. אז שמחה לבשר לכם שהקיץ הסתיים. ובזכותכם ובזכות העצות שלכם הגענו לרווח של ',
          suffix: 'שקלים',
          footerText: 'עכשיו נשארה לי התלבטות אחרונה...',
          textColor: 'text-white',
          height: 'bg-[70%]',
        },
      },
      {
        name: 'image',
        type: 'content',
        content: {
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1710749884/Decision%20making%20game/girls/flip-flops_tq4mrs.svg',
          height: '132px',
          width: '132px',
        },
      },
    ],
  },
};
export default slide50;
