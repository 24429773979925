export const slide59 = {
  id: 'slide59_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        1000: { target: '#slide59_fragments.fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'message',
        content: {
          background: '#FFE9DD',
          text: 'לקנות ומהר. זו הזדמנות מעולה לקנות בחצי מחיר. רוץ על זה...',
        },
      },
    },
    fragment2: {
      on: {
        WATCH_AUDIO: { target: '#slides.slide45' },
      },
      meta: {
        id: 'fragment2',
        name: 'audio_listener',
        content: {
          position: 5,
          event: 'WATCH_AUDIO',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1721803666/Decision%20making%20game/boy/w4h4awqdodbfcma2mn5b.mp3',
        },
      },
    },
  },
  meta: {
    id: 'slide59',
    section: '65f6bff57989236ea9a3a207',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(46.92deg, #F9ED32 -21.38%, #FBB040 180.02%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [],
    settings: {
      container: {
        backgroundColor: '#F5F5F5',
        display: 'end',
        heightPercentage: 0.77,
        header: {
          backgroundHeader: '#FFFFFF',
          title: 'הקומונה של מיכאל',
          type: 'chat',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721803267/Decision%20making%20game/boy/hnracj4xhkrgkli1zoiw.svg',
        },
      },
    },
  },
};

export default slide59;
