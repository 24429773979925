<template>
  <div
    class="relative gap-2 flex flex-col items-center"
    :style="{ width, height }">
    <div
      v-if="label"
      class="bg-secondary-250 p-1 rounded-2xl text-dark text-xs w-20">
      {{ label }}
    </div>
    <video
      :id="`video-player-${id}`"
      ref="videoRef"
      v-bind="videoProps"
      class="transition-all rounded-3xl cld-video-player cld-fluid"
      @canplay="ready = true"/>
    <div
      class="fixed top-10 left-5 cursor-pointer z-[999999999]"
      @click="togglePlay">
      <Pause v-if="playing" class="stroke-white stroke-3 fill-white" />
      <Play v-else class="stroke-white stroke-3 fill-white" />
    </div>
  </div>
  <loading-overlay
    :active="!ready"
    :can-cancel="false"
    :is-full-page="false"
    :opacity="0.8"
    :z-index="9999999"
    color="#fff"
    background-color="#333"/>
</template>
<script setup lang="ts">
import { onMounted, ref, watch } from 'vue';
import { Pause, Play } from 'lucide-vue-next';
import { useState } from '@/apps/slideshowGame/useState';
import cloudinaryPlayer from 'cloudinary-video-player';
import 'cloudinary-video-player/cld-video-player.min.css';
import ObjectID from 'bson-objectid';
import { videoStreamUrl } from '@/utils/helpers';
import LoadingOverlay from 'vue-loading-overlay';

const props = defineProps({
  src: {
    type: String,
    required: true,
  },
  autoPlay: {
    type: Boolean,
    default: true,
  },
  event: {
    type: String as any,
    required: true,
  },
  width: {
    type: String,
    default: '100%',
  },
  height: {
    type: String,
    default: '100%',
  },
  label: {
    type: String,
    default: '',
  },
});

const id = ref(new ObjectID().toString());
const videoProps = ref({});
const { handleNext } = useState();
const videoPlayer = ref<any>(null);
const ready = ref(false);
const playing = ref(false);
const ended = ref(false);

const togglePlay = () => {
  if (playing.value) {
    videoPlayer.value?.pause();
  } else {
    videoPlayer.value?.play();
  }
  playing.value = !playing.value;
};

watch(ended, value => {
  if (value) {
    playing.value = false;
    handleNext(props.event);
  }
});

if (props.autoPlay) {
  videoProps.value = { 'data-autoplay': true };
}

onMounted(() => {
  videoPlayer.value = cloudinaryPlayer.videoPlayer(
    'video-player-' + id.value,
    {},
  );
  videoPlayer.value.source(videoStreamUrl(props.src));

  videoPlayer.value.on('ended', () => {
    ended.value = true;
    playing.value = false;
  });
  videoPlayer.value.on('play', () => {
    playing.value = true;
  });
  videoPlayer.value.on('pause', () => {
    playing.value = false;
  });
});
</script>
