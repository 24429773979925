enum ActionType {
  EMOTION = 'EMOTION',
  RATIONAL = 'RATIONAL',
  PROCRASTINATION = 'PROCRASTINATION',
  APPROVAL = 'APPROVAL',
  INTUITIVE = 'INTUITIVE',
  SYSTEMATIC = 'SYSTEMATIC',
  IMPULSIVE = 'IMPULSIVE',
  INDEPENDENT = 'INDEPENDENT',
  DELIBERATION = 'DELIBERATION',
}

export default ActionType;
