export const slide57 = {
  id: 'slide57_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        6000: { target: '#slides.slide61' },
      },
      meta: {
        id: 'fragment1',
        name: 'lottie',
        type: 'content',
        content: {
          animationPath: 'final',
        },
      },
    },
  },
  meta: {
    id: 'slide57',
    display: {
      backgroundColor: '#FFD441',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-black',
          title: 'איך אתה אוכל את השלגון\n שלך ובאיזה טעם?',
          subtitle:
            'כלומר, מה דפוסי קבלת ההחלטות שבחרת במשחק זה, \n   תכף תראה את תוצאות התשובות שלך ',
        },
      },
    ],
  },
};
export default slide57;
