<template>
  <div
    class="reveal deck2 relative h-full rounded-3xl"
    :style="{ width: `${height * 0.7}px` }">
    <div
      v-if="sectionIndex != -1"
      class="fixed top-5 max-h-lg:!top-14 z-[9999] flex flex-col px-6 gap-2"
      :style="{ width: `${height * 0.7}px` }">
      <div class="flex gap-1 max-h-md:gap-2 justify-between w-full">
        <div
          v-for="(_, index) in sections"
          :key="index"
          class="h-0.5"
          :class="index <= sectionIndex ? 'bg-white' : 'bg-[#FFFFFF66]'"
          :style="{ width: `${(height * 0.7) / sections.length}px` }"></div>
      </div>
      <div
        class="fixed flex gap-2 max-h-md:gap-3 items-center w-fit mt-2 max-h-md:mt-3">
        <img
          :src="gender[userGender]?.avatar"
          alt="user_avatar"
          class="h-7 w-7 max-h-md:h-10 max-h-md:w-10 max-h-lg:h-12 max-h-lg:w-12 rounded-full"/>
        <div class="text-white text-xs max-h-md:text-sm max-h-lg:text-md">
          {{ gender[userGender]?.name }}
        </div>
      </div>
    </div>
    <div class="slides scrollbar-hide">
      <template
        v-for="slide in slideMachine.states"
        :key="`${slide.meta?.id}-${slide.meta?.v}`">
        <GameSlide :slide="slide" />
      </template>
    </div>
  </div>
</template>

<script setup lang="ts">
import { nextTick, onMounted, computed } from 'vue';
import GameSlide from '@/apps/slideshowGame/GameSlide.vue';
import { useState } from '@/apps/slideshowGame/useState';
import { useReveal } from '@/apps/slideshow/useReveal';
import { useRevealGame } from '@/apps/slideshowGame/useReveal';
import { useWindowSize } from '@vueuse/core';

const { initialize } = useRevealGame();
const { user }: { user: any } = useReveal();
const { slideMachine, sections, sectionIndex } = useState();

onMounted(() => {
  if (slideMachine.states) {
    nextTick(() => {
      initialize();
    });
  }
});
const userGender = computed(() => user.value?.gender || 'male');
const { height } = useWindowSize();
const gender = {
  male: {
    name: 'מיכאל',
    avatar:
      'https://res.cloudinary.com/dcodkxpej/image/upload/v1717961451/Decision%20making%20game/boy/avatar_kyb29f.svg',
  },
  female: {
    name: 'שירה',
    avatar:
      'https://res.cloudinary.com/dcodkxpej/image/upload/v1717961494/Decision%20making%20game/girls/avatar_1_mlygve.svg',
  },
};
</script>

<style scoped>
@import 'reveal.js/dist/reveal.css';
</style>
