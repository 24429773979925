import { endGame } from '@/apps/slideshowGame/useCalculation';

export const slide61 = {
  entry: ({ context }) => {
    endGame(context.submissions);
  },
  on: {
    CLICK_NEXT: 'slide62',
    HEAD: 'slide63',
    BELLY: 'slide64',
    HEAD_BELLY_MIDDLE: 'slide65',
    DELIBERATION_EXPLAIN: 'slide66',
    DECISION_EXPLAIN: 'slide67',
    DELIBERATION_DECISION_MIDDLE: 'slide68',
  },
  meta: {
    id: 'slide61',
    display: {
      backgroundColor: '#FFD441',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-black',
          title: 'איך אתה אוכל את השלגון\n שלך ובאיזה טעם?',
        },
      },
      {
        name: 'results_calculation',
        content: {
          titleColor: 'text-[#F6652A]',
          textColor: 'text-black',
          options: {
            'head-belly': [
              {
                title: 'ארטיק קרח רמזור',
                subtitle:
                  'שימו לב שאתם לא משקיעים יותר מדי זמן ומאמץ באיסוף המידע. כשנדרשת הכרעה מהירה, דפוס זה עשוי להקשות ולעכב את ההחלטה.',
                type: 'head',
                event: 'HEAD',
              },
              {
                title: 'בטעם קסטה שוקו וניל',
                subtitle:
                  'הבחירות שלך במשחק היו בסגנון קסטה שוקו וניל, כמו מי שבוחר על פי הרגש, באופן רך, רואה את העולם בשחור לבן, לפי הטעם שלו, וניל או שוקו, והולך על פי תחושת הבטן.',
                type: 'belly',
                event: 'BELLY',
              },
              {
                title: 'בטעם גומי-גם',
                subtitle:
                  'הבחירות שלך במשחק היו בסגנון גומי-גם. גם ראש וגם שכל, גם קרחון וגם גלידה. גם קשה וגם רך. פעם כך ופעם כך.',
                type: 'middle',
                event: 'HEAD_BELLY_MIDDLE',
              },
            ],
            'decision-deliberation': [
              {
                title: 'אתה אוכל לק אחרי לק',
                subtitle:
                  'הבחירות שלך במשחק היו דומות לבחירות של אדם שעומד דקות ארוכות מול המקפיא, בוחן את כל העטיפות של הארטיקים, ואחרי שבסופו של דבר לוקח ארטיק מסויים הוא מתענג לק אחרי לק על הארטיק גם כשהוא נמס לו בידיים...',
                type: 'deliberation',
                event: 'DELIBERATION_EXPLAIN',
              },
              {
                title: 'אתה אוכל בביסים גדולים',
                subtitle:
                  'הבחירות שלך במשחק היו דומות לבחירות של אדם שמסתפק במבט קצר על המקפיא כדי לבחור בזריזות איזה ארטיק הוא רוצה לאכול ואז לתת בו ביסים גדולים כדי שלא יימס.',
                type: 'decision',
                event: 'DECISION_EXPLAIN',
              },
              {
                title: 'אתה אוכל ביס-לק-ביס',
                subtitle:
                  'הבחירות שלך במשחק היו כמו של אדם שמשלב. מצד אחד בוחר בטיל את הטילון אבל אז מלקק אותו לאט ומתלבט אם להתחיל לנגוס מהגלידה או מהגביע. ',
                type: 'middle',
                event: 'DELIBERATION_DECISION_MIDDLE',
              },
            ],
          },
          event: 'CLICK_NEXT',
        },
      },
    ],
  },
};

export default slide61;
