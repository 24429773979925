export const slide13 = {
  on: {
    CLICK_NEXT: 'slide18',
  },
  meta: {
    id: 'slide13',
    section: '65f021ea12035cd06c39224f',
    display: {
      backgroundColor: '',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'video',
        type: 'content',
        content: {
          event: 'CLICK_NEXT',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1710150038/Decision%20making%20game/girls/3.1_%D7%AA%D7%97%D7%95%D7%A9%D7%AA_%D7%91%D7%98%D7%9F_g9cnoe.mov',
        },
      },
    ],
  },
};

export default slide13;
