import ActionType from '@/apps/slideshowGame/ActionType';

export const slide38 = {
  id: 'slide38_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      on: {
        [ActionType.APPROVAL]: { target: '#slides.slide39' },
        [ActionType.INDEPENDENT]: { target: '#slides.slide40' },
      },
      meta: {
        id: 'fragment1',
        name: 'vs_choice',
        content: {
          title: '**חברים, עדכון לייב מהשטח ',
          subtitle:
            'המארגן של ההופעה הציע לי להעביר\n את הדוכן למקום שהוא טוען שיביא לי\n יותר לקוחות. אני ממש חושבת שהוא\n טועה. המיקום שלי נפלא איפה שאני\n נמצאת. ואין לי פה דוכנים מתחרים. ',
          options: [
            {
              event: ActionType.APPROVAL,
              title: 'לקבל את ההצעה שלו                  ',
            },
            {
              event: ActionType.INDEPENDENT,
              title: 'להתעקש להשאר במקום שלי   ',
            },
          ],
        },
      },
    },
  },
  meta: {
    id: 'slide38',
    section: '65f2cf2b4a2282bb76bffc94',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(168.56deg, #FEBD59 2.89%, #FF8262 105.93%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'image',
        type: 'content',
        content: {
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1710411958/Decision%20making%20game/girls/Group_36627_zznnvh.svg',
          width: '237px',
          height: '235px',
        },
      },
    ],
  },
};
export default slide38;
