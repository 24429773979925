import {
  BaseChoice,
  BaseVideoStream,
  BaseText,
  BaseImage,
  BaseChoiceImage,
  WelcomeSlide,
  AudioListener,
  BaseMessage,
  VsChoice,
  ResultsCalculation,
  BaseLottie,
  ExpandedResult,
  EndDayAmount,
} from '@/apps/slideshowGame/components';

export const resolveComponent = name => {
  const nameToComponent = {
    video: BaseVideoStream,
    choice: BaseChoice,
    base_text: BaseText,
    choice_image: BaseChoiceImage,
    welcome_slide: WelcomeSlide,
    image: BaseImage,
    audio_listener: AudioListener,
    message: BaseMessage,
    vs_choice: VsChoice,
    end_day_amount: EndDayAmount,
    results_calculation: ResultsCalculation,
    expanded_result: ExpandedResult,
    lottie: BaseLottie,
  };

  const componentsMap = {
    ...nameToComponent,
  };

  return componentsMap[name];
};

export const getAttributes = ({ block }) => {
  const attributes: Record<string, any> = { ...block.content };
  return attributes;
};
