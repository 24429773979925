export const slide25 = {
  on: {
    CLICK_NEXT: 'slide26',
  },
  meta: {
    id: 'slide25',
    section: '65f0239512ace1f2e53cc35f',
    display: {
      backgroundColor: '',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'video',
        type: 'content',
        content: {
          event: 'CLICK_NEXT',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1721749605/Decision%20making%20game/boy/ud3emzv3j0bvrdr9v9xm.mov',
        },
      },
    ],
  },
};
export default slide25;
