export const slide55 = {
  on: {
    CLICK_NEXT: 'slide57',
  },
  meta: {
    id: 'slide55',
    section: '65f80f80a9a49e9b1dbcd6a2',
    display: {
      backgroundColor: '',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'video',
        type: 'content',
        content: {
          event: 'CLICK_NEXT',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1710761615/Decision%20making%20game/girls/7.4_%D7%A1%D7%A8%D7%98%D7%95%D7%9F_%D7%A4%D7%A8%D7%99%D7%93%D7%94_wcwv7s.mov',
        },
      },
    ],
  },
};
export default slide55;
