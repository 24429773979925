import SmallStar from '@/assets/decisionMakingGame/small-star.svg';

export const slide4 = {
  id: 'slide4_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      on: {
        WATCH_AUDIO: { target: 'fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'audio_listener',
        type: 'content',
        content: {
          position: 1,
          event: 'WATCH_AUDIO',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1721741838/Decision%20making%20game/boy/pvjeu76akaimw5g8eg5d.mp3',
        },
      },
    },
    fragment2: {
      after: {
        1000: { target: '#slide4_fragments.fragment3' },
      },
      meta: {
        id: 'fragment2',
        name: 'choice',
        type: 'content',
        content: {
          event: 'CLICK_NOYA',
          icon: '❤️',
          title: 'לביא',
          id: '65d49da6a44cd68f7abef647',
        },
      },
    },
    fragment3: {
      on: {
        CLICK_YAEL: { target: '#slide4_fragments.fragment5' },
        CLICK_NOYA: { target: '#slide4_fragments.fragment4' },
      },
      meta: {
        id: 'fragment3',
        name: 'choice',
        type: 'fragment',
        content: {
          event: 'CLICK_YAEL',
          icon: '🧠',
          title: 'נועם',
          id: '65d49daada30dc7a78d6e520',
        },
      },
    },
    fragment4: {
      on: {
        CLICK_NEXT: { target: '#slides.slide8' },
      },
      meta: {
        id: 'fragment4',
        name: 'video',
        type: 'content',
        once: true,
        content: {
          event: 'CLICK_NEXT',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1721741486/Decision%20making%20game/boy/szfcx857xqp6lcmdhtym.mov',
          autoPlay: false,
          width: '100%',
          height: '100px',
          label: 'יום שלישי',
        },
      },
    },
    fragment5: {
      on: {
        CLICK_NEXT: { target: '#slides.slide8' },
      },
      meta: {
        id: 'fragment5',
        name: 'video',
        type: 'content',
        once: true,
        content: {
          event: 'CLICK_NEXT',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1721741076/Decision%20making%20game/boy/td9h2aln9pua6hiy2mng.mov',
          autoPlay: false,
          width: '100%',
          height: '100px',
          label: 'יום שלישי ',
        },
      },
    },
  },
  meta: {
    id: 'slide4',
    section: '65f02141fca6c45fd2c1a2df',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(179.16deg, #0FD8FD 8.64%, #4AE4F5 99.27%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [],
    elements: [
      {
        src: SmallStar,
        x: '99%',
        y: '-8%',
        zIndex: 100,
      },
    ],
    settings: {
      container: {
        backgroundColor: '#FFFFFF',
        display: 'end',
        heightPercentage: 0.77,
      },
    },
  },
};

export default slide4;
