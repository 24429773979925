<template>
  <section
    ref="slide"
    :data-page="slide.meta.id"
    :data-background-color="slide.meta.display.backgroundColor"
    :data-background-gradient="slide.meta.display.backgroundGradient"
    :data-background-image="slide.meta.display.backgroundImage"
    :data-background-size="slide.meta.display.backgroundSize"
    :data-background-repeat="slide.meta.display.backgroundRepeat"
    :data-background-opacity="slide.meta.display.backgroundOpacity"
    :data-background-video="slide.meta.display.backgroundVideo"
    :data-transition="slide.meta.display.transition"
    :data-transition-speed="slide.meta.display.transitionSpeed"
    :data-background-transition="slide.meta.display.backgroundTransition"
    class="slide relative h-full !flex !flex-col items-center justify-center rounded-3xl"
    :class="{
      '!justify-end':
        slide.meta.settings?.container.display == 'end' ||
        slide.meta.display.backgroundImage,
    }">
    <GameContent class="z-20" />
    <FragmentContainer
      v-if="currentFragments.length || slide.meta.elements"
      class="relative z-30"
      :settings-container="slide.meta.settings?.container">
      <template #content>
        <template v-for="element in slide.meta.elements" :key="element.src">
          <component
            :is="element.src"
            class="hidden md:block absolute scale-75 max-h-lg:scale-100"
            :style="{
              top: element.y,
              left: element.x,
              zIndex: element.zIndex,
              width: element.width,
              height: element.height,
            }"/>
        </template>
        <FragmentsContent />
      </template>
    </FragmentContainer>

    <button
      v-if="slide.meta.event"
      class="flex absolute left-2 bottom-2 rounded-full w-8 h-8 max-h-md:!w-10 max-h-md:!h-10 max-h-lg:!w-14 max-h-lg:!h-14) items-center justify-center z-[99999] bg-[#06060666]"
      @click="handleNext(slide.meta.event)">
      <ChevronLeft class="stroke-white w-4 xl:w-8 stroke-3" />
    </button>
  </section>
</template>

<script setup lang="ts">
import { h, ref } from 'vue';
// import type { GameSlide } from '@/apps/slideshowGame/types';
import { useArrayMap } from '@vueuse/core';
import type { BuildingBlock } from '@/apps/slideshowGame/models/blocksGame';
import { getAttributes, resolveComponent } from '@/apps/slideshowGame/mappers';
import { ChevronLeft } from 'lucide-vue-next';
import FragmentContainer from '@/apps/slideshowGame/FragmentContainer.vue';
import { useState } from '@/apps/slideshowGame/useState';

const props = defineProps<{
  slide: any;
}>();

const { handleNext } = useState();
const currentFragments = ref(
  Object.values(props.slide?.states ?? {}).map(
    (fragment: any) => fragment.meta,
  ),
);

const GameContent = () => {
  const blocks = useArrayMap(
    props.slide.meta.content ?? [],
    (block: BuildingBlock) => {
      const BlockComponent = resolveComponent(block.name);
      const attributes = getAttributes({ block });
      let rendered = h(BlockComponent, attributes);
      return h('div', { class: ['w-full flex justify-center'] }, [rendered]);
    },
  );
  return h('div', { class: 'w-full flex flex-col gap-4' }, blocks.value);
};

const FragmentsContent = () => {
  const blocks = useArrayMap(
    currentFragments.value ?? [],
    (block: BuildingBlock) => {
      const BlockComponent = resolveComponent(block.name);
      const attributes = getAttributes({ block });
      let rendered = h(BlockComponent, attributes);
      return h(
        'div',
        {
          class: [
            'w-full flex justify-center am-block fragment',
            {
              '[&:not(.current-fragment)]:hidden': block.once,
              [block?.animation?.secondary ?? 'fade-up']: true,
              [block?.animation?.name ?? '']: true,
            },
          ],
        },
        [rendered],
      );
    },
  );
  return h(
    'div',
    {
      onScroll: checkScrollable,
      class:
        'fragment-container relative flex flex-col w-full h-full space-y-2 max-h-lg:space-y-3 py-3 px-3 max-h-lg:px-5 justify-start no-scrollbar',
    },
    blocks.value,
  );
};

const checkScrollable = event => {
  const isScrolledToEnd =
    event.target.scrollTop + event.target.clientHeight >=
    event.target.scrollHeight;

  if (!isScrolledToEnd) {
    event.target.classList.add('scrollable');
  } else {
    event.target.classList.remove('scrollable');
  }
};
</script>

<style>
.scrollable {
  background: linear-gradient(to top, #aaa, transparent 25px);
}
</style>
