import ActionType from '@/apps/slideshowGame/ActionType';

export const slide41 = {
  id: 'slide41_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      on: {
        [ActionType.APPROVAL]: { target: '#slides.slide42' },
        [ActionType.INDEPENDENT]: { target: '#slides.slide43' },
      },
      meta: {
        id: 'fragment1',
        name: 'vs_choice',
        content: {
          border: 'border-[#F6652A]',
          subtitle: 'מה אתם ממליצים לי לעשות? ',
          options: [
            {
              event: ActionType.INDEPENDENT,
              title: 'להישאר לבד בהופעה כמו מלך',
            },
            {
              event: ActionType.APPROVAL,
              title: 'להמשיך לפיצה עם החברים ולוותר על ההופעה',
            },
          ],
        },
      },
    },
  },
  meta: {
    id: 'slide41',
    section: '65f2cf2b4a2282bb76bffc94',
    display: {
      backgroundColor: '',
      backgroundGradient: '',
      backgroundImage:
        'https://res.cloudinary.com/dcodkxpej/image/upload/v1721802970/Decision%20making%20game/boy/lhc7j3kbhwbskwy2z8e2.jpg',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [],
  },
};

export default slide41;
