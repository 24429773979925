import ActionType from '@/apps/slideshowGame/ActionType';
import Tag from '@/assets/decisionMakingGame/tag.svg';

export const slide12 = {
  id: 'slide12_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        1000: { target: '#slide12_fragments.fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'choice',
        content: {
          event: ActionType.INTUITIVE,
          title: 'תחושת הבטן שלי אומרת שני שקלים',
        },
      },
    },
    fragment2: {
      after: {
        1000: { target: '#slide12_fragments.fragment3' },
      },
      meta: {
        id: 'fragment2',
        name: 'choice',
        content: {
          event: ActionType.SYSTEMATIC,
          title: 'ללכת לעשות סקר שוק. לבדוק בכמה מוכרים אחרים',
        },
      },
    },
    fragment3: {
      after: {
        1000: { target: '#slide12_fragments.fragment4' },
      },
      meta: {
        id: 'fragment3',
        name: 'choice',
        content: {
          event: ActionType.RATIONAL,
          title: 'שני שקלים. רווח של 200% מספיק',
        },
      },
    },
    fragment4: {
      after: {
        1000: { target: '#slide12_fragments.fragment5' },
      },
      meta: {
        id: 'fragment4',
        name: 'choice',
        content: {
          event: ActionType.APPROVAL,
          title: 'מה אני מבינה בזה? אשאל את השותפה              ',
        },
      },
    },
    fragment5: {
      on: {
        [ActionType.INTUITIVE]: { target: '#slides.slide13' },
        [ActionType.SYSTEMATIC]: { target: '#slides.slide14' },
        [ActionType.RATIONAL]: { target: '#slides.slide15' },
        [ActionType.APPROVAL]: { target: '#slides.slide16' },
        [ActionType.EMOTION]: { target: '#slides.slide17' },
      },
      meta: {
        id: 'fragment5',
        name: 'choice',
        content: {
          event: ActionType.EMOTION,
          title:
            'האמת, לא נעים להרוויח כל כך הרבה על חשבון ילדים מהשכונה. שקל וחצי גם טוב              ',
        },
      },
    },
  },
  meta: {
    id: 'slide12',
    section: '65f021aa65c867bea9e07224',
    display: {
      backgroundColor: '#E161F5',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-white',
          title: 'עזרו לי להחליט',
          subtitle:
            'מצאתי ספק ארטיקים שאם נקנה כמות גדולה, מוכן\n למכור כל ארטיק בחצי שקל.',
        },
      },
    ],
    elements: [
      {
        src: Tag,
        x: '85%',
        y: '-40px',
        zIndex: 100,
        width: '120px',
      },
    ],
    settings: {
      container: {
        backgroundColor: '#FFFFFF',
        borderRadius: '24px',
        header: {
          backgroundHeader: '#252526',
          title: 'בכמה מוכרים? ',
        },
      },
    },
  },
};

export default slide12;
