export const slide63 = {
  id: 'slide63',
  on: {
    BACK: 'slide61',
  },
  meta: {
    id: 'slide63',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(170.15deg, #BA60FE -17.65%, #D918FD 61.41%, #FBB2C8 145.09%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'expanded_result',
        content: {
          textColor: 'prose-p:text-white',
          option: {
            title: 'ארטיק קרח רמזור.',
            subtitle: `<p>
זה מתאים למי שמסתמך על איסוף נתונים ומבצע תהליך בחירה שיטתי וסדור.</p>
<p>
נראה שכשאת צריכה לקבל החלטות, את משתדלת להפעיל שיקול דעת ולבחון את היתרונות והחסרונות של כל אפשרות לפני ההכרעה ביניהן.</p>
<p>
זאת גישה שבאה מהתפיסה שביצוע תהליך סדור יסייע לקבל את ״ההחלטה המתאימה ביותר״ והיא עוזרת לנו במקרים בהם נדרשות החלטות משמעותיות
ויש לנו מידע רב להסתמך עליו.</p>
<p>
שימו לב שאתם לא משקיעים יותר מדי זמן ומאמץ באיסוף המידע. כשנדרשת הכרעה מהירה, דפוס זה עשוי להקשות ולעכב את ההחלטה.</p>`,
          },
          event: 'BACK',
        },
      },
    ],
    settings: {
      container: {
        backgroundColor: '#F5F5F5',
        header: {
          backgroundHeader: '#FFFFFF',
          title: 'הקומונה של רונה',
          type: 'chat',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1710679982/Decision%20making%20game/girls/Oval_s83qad.svg',
        },
      },
    },
  },
};

export default slide63;
