//@ts-nocheck
import Reveal from 'reveal.js';
import type { Api as RevealApi } from 'reveal.js';
import { computed, ref } from 'vue';
import { createEventHook } from '@vueuse/core';

let presentationManager: RevealApi | any = null;
const defaultSettings: Reveal.Options | any = {
  controls: false,
  progress: false,
  center: true,
  transition: 'slide',
  transitionSpeed: 'default',
  backgroundTransition: 'fade',
  controlsLayout: 'bottom-right',
  embedded: true,
  width: '100%',
  height: '100%',
  margin: 0,
  rtl: true,
  fragments: true,
  keyboard: false,
  scrollActivationWidth: null,
};

const onFragmentShown = createEventHook<any>();

const toSlide = (indexh: number, indexv: number, indexf: number) => {
  presentationManager?.slide(indexh, indexv, indexf);
};

const initializePresentation = async () => {
  const deckElement = document.querySelector('.deck2');

  if (!deckElement) {
    //FIXME - TRY NOT TO THROW ERROR
    throw new Error('Deck element not found');
  }

  const revealConfig: Reveal.Options = {
    ...defaultSettings,
  };

  presentationManager = new Reveal(deckElement, revealConfig);
  await presentationManager.initialize();
};

const initialize = async () => {
  presentationManager?.destroy();
  await initializePresentation();
  showFragment();
};

const currentShownFragment = ref();
const currentSlide = computed(() => presentationManager?.getCurrentSlide());

const showFragment = () => {
  presentationManager?.on('fragmentshown', function (event) {
    currentShownFragment.value = event.fragment;
    const currentSlide = presentationManager?.getCurrentSlide();
    const fragmentContainer = currentSlide.querySelector('.fragment-container');

    onFragmentShown.trigger(currentShownFragment.value);

    if (fragmentContainer.classList.contains('overflow')) return;

    fragmentContainer.classList.remove('overflow-y-auto');

    const containerStyle = window.getComputedStyle(fragmentContainer);
    const visibleFragments = fragmentContainer.querySelectorAll('.visible');
    const fragmentsHeight = Array.from(visibleFragments).reduce((sum, el) => {
      const fragmentStyle = window.getComputedStyle(el);
      return (
        sum +
        el.scrollHeight +
        parseInt(fragmentStyle.marginBottom) +
        parseInt(fragmentStyle.marginTop)
      );
    }, 0);

    if (
      visibleFragments.length > 1 &&
      fragmentsHeight >
        fragmentContainer?.clientHeight -
          parseInt(containerStyle.paddingTop) -
          parseInt(containerStyle.paddingBottom)
    ) {
      fragmentContainer.classList.add('overflow-y-auto');
      fragmentContainer.classList.add('scrollable');
    }
  });
};
export function useRevealGame() {
  return {
    currentSlide,
    currentShownFragment,
    toSlide,
    initialize,
    onFragmentShown: onFragmentShown.on,
  };
}
