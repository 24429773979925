import { createMachine, createActor } from 'xstate';
import { slideModules } from '@/apps/slideshowGame/slides/index';
import { ref, computed } from 'vue';
import { useRevealGame } from '@/apps/slideshowGame/useReveal';
import emitter from '@/emitter';
import ActionType from '@/apps/slideshowGame/ActionType';
import { useAnimation } from '@/apps/slideshowGame/animations/useAnimation';

const { toSlide, onFragmentShown } = useRevealGame();
const { initialAnimation } = useAnimation();
const sectionIndex = ref<number>(0);
const currentFragments = ref();
const fragmentIndex = ref();
const currentFragment = computed(
  () => currentFragments.value[fragmentIndex.value],
);
onFragmentShown(currentFragment => {
  const animation = currentFragments.value[fragmentIndex.value].animation;
  if (animation && typeof animation === 'object') {
    initialAnimation(
      currentFragments.value[fragmentIndex.value],
      currentFragment,
    );
  }
});

const decisionAction = ref<any[]>([
  ActionType.APPROVAL,
  ActionType.PROCRASTINATION,
]);

const sections = ref([
  '65cddd5470946218de8b3da6',
  '65f021098fbf2923bbe4a361',
  '65f02141fca6c45fd2c1a2df',
  '65f02174f18b670782f05ff4',
  '65f0218666c26d474c6cd587',
  '65f021aa65c867bea9e07224',
  '65f021ea12035cd06c39224f',
  '65f0239512ace1f2e53cc35f',
  '65f024256da2c5fe406d55a9',
]);

const sectionsPart2 = [
  '65f2c85f8861cdcb662a47d4',
  '65f2cab0c06278d82f80b2e7',
  '65f2cf2b4a2282bb76bffc94',
  '65f6bd2b260611eab1b11adb',
  '65f6bff57989236ea9a3a207',
  '65f6fd56ea41daf4eb6fbd34',
  '65f7f73c59aed15bcec0dedf',
  '65f80dc09fc1182456fc1a23',
  '65f80f80a9a49e9b1dbcd6a2',
];

const switchSections = () => {
  sections.value = sectionsPart2;
  sectionIndex.value = 0;
};

const isLastFragment = computed(() => {
  const lastFragmentIndexByName = currentFragments.value?.findLastIndex(
    fragment => fragment.name === 'choice',
  );
  return lastFragmentIndexByName === fragmentIndex.value;
});
const isValidActionType = (eventType: string) => {
  return Object.keys(ActionType).includes(eventType as ActionType);
};

const updateSubmissions = (context, event) => {
  const currentSection = sections.value[sectionIndex.value];

  if (!context.submissions[currentSection]) {
    context.submissions[currentSection] = [];
  }

  if (!decisionAction.value.includes(event.type)) {
    context.submissions[currentSection].push(
      event.type,
      ActionType.DELIBERATION,
    );
  } else {
    context.submissions[currentSection].push(event.type);
  }
};

const slideMachine = createMachine({
  id: 'slides',
  initial: 'slide1',
  context: {
    submissions: {},
    metadata: {
      count: 0,
      text: '',
    },
  },
  always: {
    actions: ({ context, event }) => {
      if (isValidActionType(event.type)) {
        updateSubmissions(context, event);
      }
    },
  },
  on: {
    setSubtitle: {
      actions: ({ event }) => {
        const slide = getCurrentSlide(event.slideId)!;
        const contentItem = slide.meta.content.find(
          item => item.name === 'base_text',
        );
        contentItem.content.subtitle = event.subtitle;
        slide.meta.v++;
      },
    },
  },
  states: slideModules,
});

const actor = createActor(slideMachine);

const sendEvent = (subtitle, slideId) => {
  actor.send({ type: 'setSubtitle', subtitle, slideId });
};

const handleNext = (event: string | ActionType, fragmentId = 'fragment') => {
  if (event === 'FINAL') {
    emitter.emit('close-game-modal', actor.getSnapshot().context.submissions);
  }
  actor.send({ type: event, query: fragmentId });
};

const getCurrentSlide = (slideId: string) => {
  return Object.values(slideMachine.states).find(
    slide => slide.meta.id === slideId,
  );
};

actor.subscribe(state => {
  let indexh = 1;
  let indexv = 1;
  let indexf = 1;

  if (sectionIndex.value === sections.value.length - 1) {
    switchSections();
  }

  const slideId =
    typeof state.value === 'string' ? state.value : Object.keys(state.value)[0];
  const fragmentName = typeof state.value === 'object' && state.value[slideId];
  const slide = getCurrentSlide(slideId)!;

  if (slide) {
    sectionIndex.value = sections.value.findIndex(
      section => section === slide?.meta?.section,
    );
    const slides = Object.keys(slideMachine.states);
    indexh = slides.indexOf(slide.key);

    if (typeof state.value === 'object') {
      currentFragments.value = Object.values(slide.states).map(
        fragment => fragment.meta,
      );

      indexf = currentFragments.value.findIndex(
        fragment => fragment.id === fragmentName,
      );
      fragmentIndex.value = indexf;
    }
  }
  toSlide(indexh, indexv, indexf);
});

actor.start();

export function useState() {
  return {
    sections,
    actor,
    sectionIndex,
    isLastFragment,
    slideMachine,
    currentFragment,
    handleNext,
    sendEvent,
  };
}
