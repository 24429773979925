export const slide48 = {
  on: {
    CLICK_NEXT: 'slide50',
  },
  meta: {
    id: 'slide48',
    section: '65f7f73c59aed15bcec0dedf',
    display: {
      backgroundColor: '',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'video',
        type: 'content',
        content: {
          event: 'CLICK_NEXT',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1710750611/Decision%20making%20game/girls/5.3_%D7%94%D7%9E%D7%A9%D7%9B%D7%AA%D7%99_%D7%9C%D7%9E%D7%9B%D7%95%D7%A8_%D7%90%D7%91%D7%9C_%D7%95%D7%9C%D7%9E%D7%A8%D7%95%D7%AA_%D7%94%D7%97%D7%A8%D7%9D_%D7%94%D7%A2%D7%A1%D7%A7_%D7%9C%D7%90_%D7%A0%D7%A4%D7%92%D7%A2_n4oxjp.mov',
        },
      },
    ],
  },
};
export default slide48;
