export const slide68 = {
  id: 'slide68',
  on: {
    BACK: 'slide61',
  },
  meta: {
    id: 'slide68',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(170.15deg, #BA60FE -17.65%, #D918FD 61.41%, #FBB2C8 145.09%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'expanded_result',
        content: {
          textColor: 'prose-p:text-white',
          option: {
            title: 'את אוכלת ביס-לק-ביס',
            subtitle: `<p>זה מתאים לאנשים שמשלבים החלטות עם רצון להכריע ו”לחתוך” מצד אחד, לצד החלטות שאופיינו בהתלבטות רבה וקושי לבחור.</p>
                            <p>חלק מההחלטות היו מהירות ונבעו מהתפיסה כי “החלטה עדיפה על אי החלטה” גם כאשר לא כל המידע קיים או לא בטוח כי הבחירה שנעשתה היא “האפשרות
המושלמת” וכי ניתן להיות מרוצים גם מהחלטה כזו.</p>
<p>חלק מההחלטות התאפיינו דווקא בהתלבטות רבה וזהירות בקבלת החלטה תוך רצון לקבל עוד מידע ולבחון לעומק את הנושא. מתוך הקושי להחליט קיים ניסיון
לדחות את ההחלטה או להעביר את האחריות להחלטה למישהו אחר.</p>
<p>חשוב לדעת לאזן – להצליח לקבל החלטות זהירות ומחושבות, אבל לדעת שאין החלטה מושלמת, ולכן לפעמים צריך לקבל החלטה גם אם לא שלמים איתה.</p>`,
            type: 'middle',
          },
          event: 'BACK',
        },
      },
    ],
    settings: {
      container: {
        backgroundColor: '#F5F5F5',
        header: {
          backgroundHeader: '#FFFFFF',
          title: 'הקומונה של רונה',
          type: 'chat',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1710679982/Decision%20making%20game/girls/Oval_s83qad.svg',
        },
      },
    },
  },
};

export default slide68;
