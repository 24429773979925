import ActionType from '@/apps/slideshowGame/ActionType';
import HandCash from '@/assets/decisionMakingGame/cash-payment-method.svg';
export const slide51 = {
  id: 'slide51_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        1000: { target: '#slide51_fragments.fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'choice',
        content: {
          event: ActionType.APPROVAL,
          title: 'תשאל את ההורים. בכל זאת מדובר בסכום גבוה.',
        },
      },
    },
    fragment2: {
      after: {
        1000: { target: '#slide51_fragments.fragment3' },
      },
      meta: {
        id: 'fragment2',
        name: 'choice',
        content: {
          event: ActionType.IMPULSIVE,
          title:
            'מגיע לך ליהנות ממה שבא לך. תוציא הכל על מה שאתה אוהב.             ',
        },
      },
    },
    fragment3: {
      after: {
        1000: { target: '#slide51_fragments.fragment4' },
      },
      meta: {
        id: 'fragment3',
        name: 'choice',
        content: {
          event: ActionType.EMOTION,
          title: 'תזמין את המשפחה שלך למשהו מפנק, אחרי הכל אין כמו המשפחה.   ',
        },
      },
    },
    fragment4: {
      after: {
        1000: { target: '#slide51_fragments.fragment5' },
      },
      meta: {
        id: 'fragment4',
        name: 'choice',
        content: {
          event: ActionType.SYSTEMATIC,
          title: 'תעשה טבלה של כל האפשרויות ותראה פלוסים ומינוסים באופן מסודר.',
        },
      },
    },
    fragment5: {
      on: {
        [ActionType.APPROVAL]: { target: '#slides.slide52' },
        [ActionType.IMPULSIVE]: { target: '#slides.slide53' },
        [ActionType.EMOTION]: { target: '#slides.slide54' },
        [ActionType.SYSTEMATIC]: { target: '#slides.slide55' },
        [ActionType.RATIONAL]: { target: '#slides.slide56' },
      },
      meta: {
        id: 'fragment5',
        name: 'choice',
        content: {
          event: ActionType.RATIONAL,
          title: 'אחרי כל העבודה הקשה, לא חבל לבזבז בבת אחת? תחסוך!',
        },
      },
    },
  },
  meta: {
    id: 'slide51',
    section: '65f80dc09fc1182456fc1a23',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(179.16deg, #0FD8FD 8.64%, #4AE4F5 99.27%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          marginTop: 'mt-5',
          title: 'מה לעשות עם הכסף?',
          subtitle:
            'אני פותח סקר אחרון, חשבתי על אייפון חדש,\n סומך עליכם שתעזרו לי להחליט נכון',
          color: 'text-white',
        },
      },
    ],
    elements: [
      {
        src: HandCash,
        x: '-32%',
        y: '-24%',
        zIndex: 100,
        height: '130px',
      },
    ],
    settings: {
      container: {
        backgroundColor: '#FFFFFF',
        borderRadius: '24px',
        marginTop: '15px',
        header: {
          backgroundHeader: '#252526',
          title: 'מה אתם אומרים? ',
        },
      },
    },
  },
};
export default slide51;
