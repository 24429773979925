export const slide2 = {
  on: {
    CLICK_NEXT: 'slide3',
  },
  meta: {
    id: 'slide2',
    section: '65cddd5470946218de8b3da6',
    title: 'slide 2',
    display: {
      backgroundColor: '#1E1E1E',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'video',
        type: 'content',
        content: {
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1721733846/Decision%20making%20game/boy/qmbgbofdxcnr8ewigm2s.mov',
          event: 'CLICK_NEXT',
        },
      },
    ],
  },
};

export default slide2;
