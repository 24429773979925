export const slide67 = {
  id: 'slide67',
  on: {
    BACK: 'slide61',
  },
  meta: {
    id: 'slide67',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(170.15deg, #BA60FE -17.65%, #D918FD 61.41%, #FBB2C8 145.09%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'expanded_result',
        content: {
          textColor: 'prose-p:text-white',
          option: {
            title: 'את אוכלת בביסים גדולים',
            subtitle: `<p>
זה מתאים לאנשים שיש להם רצון לבחור ולהכריע לגבי מה לעשות או כיצד לפעול.</p>
<p>
נראה שהבנת, שעדיף להחליט גם אם אין את כל המידע, כי זה עדיף מאשר לא לקבל החלטה בכלל.</p>
<p>
מאפיין זה מאפשר לחיות בשלום עם החלטות שבהסתכלות לאחור קיימת לגביהן חרטה או שהבחירה לא הוכיחה את עצמה, מתוך הבנה שאין החלטה נכונה אחת בלבד ושאפשר להיות מרוצים מהבחירה גם אם היא לא מושלמת.</p>
<p>
שימי לב שלפעמים יש מצבים בהם דווקא נדרשת העמקה נוספת, ככה שלא תמיד הרצון לבחור ו״לחתוך״ משרת אותנו.</p>`,
            type: 'decision',
          },
          event: 'BACK',
        },
      },
    ],
    settings: {
      container: {
        backgroundColor: '#F5F5F5',
        header: {
          backgroundHeader: '#FFFFFF',
          title: 'הקומונה של רונה',
          type: 'chat',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1710679982/Decision%20making%20game/girls/Oval_s83qad.svg',
        },
      },
    },
  },
};

export default slide67;
