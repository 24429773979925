import ActionType from '@/apps/slideshowGame/ActionType';
import BigPinkStar from '@/assets/decisionMakingGame/big-pink-star.svg';
import SmallPinkStar from '@/assets/decisionMakingGame/small-pink-star.svg';
import { assign } from 'xstate';

export const slide19 = {
  id: 'slide19_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        1000: { target: '#slide19_fragments.fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'choice',
        content: {
          event: ActionType.SYSTEMATIC,
          title: 'חייב להתייעץ דחוף עם השותפה ושנקבע מה עושים במקרים כאלה. ',
        },
      },
    },
    fragment2: {
      after: {
        1000: { target: '#slide19_fragments.fragment3' },
      },
      meta: {
        id: 'fragment2',
        name: 'choice',
        content: {
          event: ActionType.PROCRASTINATION,
          title: 'לא לעשות כלום. לחכות ולראות מה יקרה בהמשך.',
        },
      },
    },
    fragment3: {
      after: {
        1000: {
          target: '#slide19_fragments.fragment4',
        },
      },
      meta: {
        id: 'fragment3',
        name: 'choice',
        content: {
          id: 'fragment3',
          event: ActionType.EMOTION,
          title: 'לא לפחד. להמשיך בדרך הצודקת ולא לחשוש מאיומים.',
        },
      },
    },
    fragment4: {
      after: {
        1000: {
          target: '#slide19_fragments.fragment5',
        },
      },
      meta: {
        id: 'fragment4',
        name: 'choice',
        content: {
          id: 'fragment4',
          event: ActionType.EMOTION,
          title: 'לסגור את הדוכן להיום. לא צריכה את כל הלחץ הזה.',
        },
      },
    },
    fragment5: {
      on: {
        [ActionType.SYSTEMATIC]: {
          target: '#slides.slide20',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 72,
              text: 'בגלל כל הבלאגן מכרנו קצת פחות \n מהרגיל, אבל זה בסדר',
            };
          }),
        },
        [ActionType.PROCRASTINATION]: {
          target: '#slides.slide21',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 150,
              text: 'למרות כל הבלגן,  בסוף היה יום \n  ממש חזק בעסק',
            };
          }),
        },
        [ActionType.EMOTION]: [
          {
            target: '#slides.slide22',
            guard: ({ event }) => event.query === 'fragment3',
            actions: assign(({ context }) => {
              context.metadata = {
                count: 6,
                text: 'עם כל הבלאגן הזה לא\n הרווחנו כלום',
              };
            }),
          },
          {
            target: '#slides.slide23',
            guard: ({ event }) => event.query === 'fragment4',
            actions: assign(({ context }) => {
              context.metadata = {
                count: 24,
                text: 'עם כל הבלאגן הזה לא\n הרווחנו כלום',
              };
            }),
          },
        ],
        [ActionType.RATIONAL]: {
          target: '#slides.slide24',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 30,
              text: 'עם כל הבלאגן הזה לא\n הרווחנו כלום',
            };
          }),
        },
      },
      meta: {
        id: 'fragment5',
        name: 'choice',
        content: {
          event: ActionType.RATIONAL,
          title: 'לעשות מבצע 1+1 שיביא לקוחות אחרים.',
        },
      },
    },
  },
  meta: {
    id: 'slide19',
    section: '65f0239512ace1f2e53cc35f',
    display: {
      backgroundColor: '#F7CF49',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          marginTop: 'mt-12',
          subtitle: 'default',
        },
      },
    ],
    elements: [
      {
        src: BigPinkStar,
        x: '100%',
        y: '40%',
        zIndex: 100,
      },
      {
        src: SmallPinkStar,
        x: '-15%',
        y: '-10px',
        zIndex: 100,
      },
    ],
    settings: {
      container: {
        backgroundColor: '#FFFFFF',
        borderRadius: '24px',
        header: {
          backgroundHeader: '#252526',
          title: ' הבלגן לא נגמר... \n מה לדעתכם כדאי לי לעשות?',
        },
      },
    },
  },
};
export default slide19;
