import BigPinkStar from '@/assets/decisionMakingGame/big-pink-star.svg';
import SmallPinkStar from '@/assets/decisionMakingGame/small-pink-star.svg';

export const slide23 = {
  id: 'slide23_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        1000: { target: '#slide23_fragments.fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'message',
        content: {
          text: 'לסגור את הדוכן להיום. לא צריכה את כל הלחץ הזה.              ',
        },
      },
    },
    fragment2: {
      on: {
        WATCH_AUDIO: { target: '#slides.slide32' },
      },
      meta: {
        id: 'fragment2',
        name: 'audio_listener',
        content: {
          position: 2,
          event: 'WATCH_AUDIO',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1710159267/Decision%20making%20game/girls/2.4_%D7%90%D7%9D_%D7%91%D7%97%D7%A8%D7%95_%D7%9C%D7%A1%D7%92%D7%95%D7%A8_%D7%90%D7%AA_%D7%94%D7%93%D7%95%D7%9B%D7%9F_-_%D7%99%D7%95%D7%9D_%D7%A1%D7%99%D7%95%D7%98_pggwyy.mp3',
        },
      },
    },
  },
  meta: {
    id: 'slide23',
    section: '65f0239512ace1f2e53cc35f',
    display: {
      backgroundColor: '#FFCD14',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [],
    elements: [
      {
        src: BigPinkStar,
        x: '100%',
        y: '80px',
        zIndex: 100,
      },
      {
        src: SmallPinkStar,
        x: '-15%',
        y: '-10px',
        zIndex: 100,
      },
    ],
    settings: {
      container: {
        backgroundColor: '#FFFFFF',
        display: 'end',
        heightPercentage: 0.77,
      },
    },
  },
};
export default slide23;
