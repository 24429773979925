import BigPinkStar from '@/assets/decisionMakingGame/big-orange-star.svg';
import SmallPinkStar from '@/assets/decisionMakingGame/small-orange-star.svg';

export const slide28 = {
  id: 'slide28_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        1000: { target: '#slide28_fragments.fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'message',
        content: {
          background: '#FFE9DD',
          text: 'אל תהיה פראייר. תסגור את הדוכן ומהר',
        },
      },
    },
    fragment2: {
      on: {
        WATCH_AUDIO: { target: '#slides.slide32' },
      },
      meta: {
        id: 'fragment2',
        name: 'audio_listener',
        content: {
          position: 2,
          event: 'WATCH_AUDIO',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1721750159/Decision%20making%20game/boy/skcdozinpobx2fmgqob9.mp3',
        },
      },
    },
  },
  meta: {
    id: 'slide28',
    section: '65f0239512ace1f2e53cc35f',
    display: {
      backgroundColor: '#FFCD14',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [],
    elements: [
      {
        src: BigPinkStar,
        x: '100%',
        y: '80px',
        zIndex: 100,
      },
      {
        src: SmallPinkStar,
        x: '-15%',
        y: '-10px',
        zIndex: 100,
      },
    ],
    settings: {
      container: {
        backgroundColor: '#FFFFFF',
        display: 'end',
        heightPercentage: 0.77,
      },
    },
  },
};
export default slide28;
