import { endDay } from '@/apps/slideshowGame/useCalculation';

export const slide32 = {
  entry: ({ context }) => {
    endDay(context.metadata);
  },
  on: {
    CLICK_NEXT: 'slide33',
  },
  meta: {
    id: 'slide32',
    section: '65f024256da2c5fe406d55a9',
    event: 'CLICK_NEXT',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(167deg, #BA60FE -15.54%, #D918FD 38.12%, #FBB2C8 94.91%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'end_day_amount',
        type: 'content',
        content: {
          background:
            'linear-gradient(166.69deg, #BA60FE -14.98%, #D918FD 45.42%, #FBB2C8 109.36%)',
          textColor: 'text-white',
          height: 'bg-[50%]',
          prefix: 'הצלחנו להגיע היום לרווח של ',
          suffix: 'שקלים.',
        },
      },
    ],
  },
};
export default slide32;
