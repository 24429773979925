export const slide66 = {
  id: 'slide66',
  on: {
    BACK: 'slide61',
  },
  meta: {
    id: 'slide66',
    display: {
      backgroundColor: '#FFD441',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'expanded_result',
        content: {
          titleColor: 'text-[#F6652A]',
          returnColor: 'text-black',
          option: {
            title: 'אתה אוכל לק אחרי לק',
            subtitle: `<p>
זה מתאים לאנשים שמתלבטים ומנסים לדחות את ההחלטה או להיעזר באחרים בקבלתה.
</p>
<p>נראה שלפעמים קשה לך לבחור מתוך כובד האחריות והחשש לקבל החלטה שתתחרט עליה. </p>
<p>
זאת גישה זהירה שבאה מתוך רצון לבדוק לעומק ולקבל את כל המידע והביטחון לפני שמקבלים החלטה, ומתוך אמונה כי קיימת החלטה נכונה אחת בלבד, ועוד מידע, זמן, או דעות יעזרו להגיע אליה.
</p>
 <p>לפעמים הגישה הזאת גורמת לנו גם להעביר את הבחירה למישהו אחר, כדי לא לשאת באחריות.</p>

<p>שימו לב שלפעמים יש מצבים בהם אם לא נקבל החלטה מהירה, לא נוכל להשפיע כלל, ולכן לפעמים עדיף לקבל החלטות גם אם אין לנו את כל המידע או אם אנחנו לא
בטוחים בהחלטה.</p>`,
            type: 'deliberation',
          },
          event: 'BACK',
        },
      },
    ],
    settings: {
      container: {
        backgroundColor: '#F5F5F5',
        header: {
          backgroundHeader: '#FFFFFF',
          title: 'הקומונה של מיכאל',
          type: 'chat',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721803267/Decision%20making%20game/boy/hnracj4xhkrgkli1zoiw.svg',
        },
      },
    },
  },
};

export default slide66;
