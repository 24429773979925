export const slide43 = {
  on: {
    CLICK_NEXT: 'slide44',
  },
  meta: {
    id: 'slide43',
    section: '65f6bd2b260611eab1b11adb',
    event: 'CLICK_NEXT',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(168.56deg, #FEBD59 2.89%, #FF8262 105.93%) ',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'image',
        type: 'content',
        content: {
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1710754496/Decision%20making%20game/girls/Post_Skilly_zqkviv.svg',
        },
      },
    ],
  },
};
export default slide43;
