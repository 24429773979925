export const slide37 = {
  id: 'slide37_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      on: {
        WATCH_AUDIO: { target: '#slides.slide38' },
      },
      meta: {
        id: 'fragment1',
        name: 'audio_listener',
        content: {
          position: 1,
          event: 'WATCH_AUDIO',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1721801847/Decision%20making%20game/boy/cnmcjbkqocupdgrwsyna.mp3',
        },
      },
    },
  },
  meta: {
    id: 'slide37',
    section: '65f2cab0c06278d82f80b2e7',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(165.02deg, #FD8031 -7.55%, #FFDF67 113.87%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [],
    settings: {
      container: {
        backgroundColor: '#FFFFFF',
        display: 'end',
        heightPercentage: 0.77,
      },
    },
  },
};
export default slide37;
