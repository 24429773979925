<template>
  <div class="w-full flex justify-between items-start gap-2">
    <div
      v-for="(option, index) in options"
      :key="index"
      class="cursor-pointer gap-2 px-1 max-h-md:px-2 flex flex-col items-center w-full"
      :class="{ active: option.event === currentEvent }"
      @click="goNext(option.event)">
      <img
        :data-src="option.src"
        :alt="option.event"
        class="border border-3 rounded-2xl w-full"
        :class="
          option.event === currentEvent
            ? `border-[${borderColor}]`
            : 'border-[#E3E3E3]'
        "/>
      <div
        class="bg-white flex items-center justify-center rounded-lg text-xxs xl:text-xs p-2">
        {{ option.title }}
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useState } from '@/apps/slideshowGame/useState';

defineProps({
  options: {
    type: Array<{ src: string; event: string; title: string }>,
    required: true,
  },
  borderColor: {
    type: String,
    default: '#C83FFE',
  },
});
const currentEvent = ref();
const { handleNext } = useState();

const goNext = event => {
  currentEvent.value = event;
  setTimeout(() => {
    handleNext(event);
  }, 1500);
};
</script>

<style scoped>
.active {
  transform: scale(1.1);
  transition: transform 0.5s ease;
}
</style>
