export const slide64 = {
  id: 'slide64',
  on: {
    BACK: 'slide61',
  },
  meta: {
    id: 'slide64',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(170.15deg, #BA60FE -17.65%, #D918FD 61.41%, #FBB2C8 145.09%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'expanded_result',
        content: {
          textColor: 'prose-p:text-white',
          option: {
            title: 'בטעם קסטה שוקו וניל',
            subtitle: `<p>
זה מתאים למי שמסתמך על ״תחושת בטן״ והרגשות שמעוררות בו האפשרויות השונות.
</p>
<p>
נראה שלפעמים אתה מקבל החלטות מתוך אינטואיציה והתחושות שהאפשרויות השונות מעוררות בך.</p>
<p>
זאת גישה שאומנם מאפשרת החלטות מהירות וזריזות, אבל מצד שני מתבססת
בעיקר על התחושות שלכם ולא על מידע נוסף שיכול לתת לכם תמונה רחבה יותר,
ויכול להיות שיהיה לכם קשה להסביר מדוע קיבלתם את ההחלטה.</p>

<p> תחושת הבטן שלכם חשובה מאוד בקבלת ההחלטות שלכם, אבל כשאתם מקבלים החלטה חשובה, שימו לב אתם לא מפספסים מידע חשוב שיכול לשנות את כל התמונה.</p>`,
          },
          event: 'BACK',
        },
      },
    ],
    settings: {
      container: {
        backgroundColor: '#F5F5F5',
        header: {
          backgroundHeader: '#FFFFFF',
          title: 'הקומונה של רונה',
          type: 'chat',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1710679982/Decision%20making%20game/girls/Oval_s83qad.svg',
        },
      },
    },
  },
};

export default slide64;
