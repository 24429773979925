import { ref } from 'vue';

const isAnimating = ref(false);
let styleSheet;

const animation = ref();
const currentElementHTML = ref();
const currentElement = ref();

const initialAnimation = (element, HTMLElement) => {
  isAnimating.value = true;
  currentElement.value = element;
  currentElementHTML.value = HTMLElement;
  animation.value = currentElement.value.animation;
  const values = animation.value?.initialValues(
    HTMLElement.parentElement,
    HTMLElement,
  );
  animation.value?.startAddClasses.forEach(initialClass => {
    currentElementHTML.value.classList.add(initialClass);
  });
  createKeyframes(animation.value?.keyframes, values);
};

const createKeyframes = (keyframes, fr) => {
  if (styleSheet) {
    styleSheet.remove();
    styleSheet = null;
  }

  styleSheet = document.createElement('style');
  styleSheet.type = 'text/css';
  styleSheet.innerText = keyframes(fr);
  document.head.appendChild(styleSheet);
};

const startAnimation = next => {
  currentElementHTML.value.classList.add('start-animation');
  setTimeout(() => {
    animation.value.endRemoveClasses.forEach(initialClass => {
      currentElementHTML.value.classList.remove(initialClass);
    });
    currentElementHTML.value.classList.remove('start-animation');
    isAnimating.value = false;
  }, animation.value.animationSeconds);

  setTimeout(() => {
    animation.value.events.forEach(event => {
      next(event);
    });
  }, animation.value.animationSeconds + 1000);
};

export function useAnimation() {
  return {
    initialAnimation,
    isAnimating,
    startAnimation,
  };
}
