export const slide16 = {
  on: {
    CLICK_NEXT: 'slide18',
  },
  meta: {
    id: 'slide16',
    section: '65f021ea12035cd06c39224f',
    display: {
      backgroundColor: '',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'video',
        type: 'content',
        content: {
          event: 'CLICK_NEXT',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1721744564/Decision%20making%20game/boy/n18iky8urn904rcyovc3.mov',
        },
      },
    ],
  },
};
export default slide16;
