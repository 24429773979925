<template>
  <div class="flex flex-col items-center space-y-2">
    <div v-if="images" class="flex gap-4 relative">
      <img class="absolute top-9 right-20" :src="src" alt="vs" />
      <div v-for="img in images" :key="img">
        <img :src="img" :alt="img" class="w-30 h-30" />
      </div>
    </div>
    <div
      v-if="title"
      class="whitespace-pre-line leading-5 font-anomaliaBold text-base max-h-md:text-lg">
      {{ title }}
    </div>
    <div
      v-if="subtitle"
      class="whitespace-pre-line leading-6 tracking-wider font-anomaliaBold text-white">
      {{ subtitle }}
    </div>
    <div
      class="grid grid-cols-2 w-full gap-3 max-h-md:gap-5 px-8 max-h-md:px-10 max-h-lg:px-20">
      <div v-for="(option, index) in options" :key="index">
        <div
          class="bg-white cursor-pointer border border-4 h-18 lg:h-22 w-full flex items-center justify-center font-anomaliaBold rounded-2xl text-xs max-h-md:text-sm p-2"
          :class="
            option.event === currentEvent
              ? `active border-b-8 border-r-8 ${border}`
              : 'border-[#E3E3E3]'
          "
          @click="goNext(option.event)">
          {{ option.title }}
        </div>
      </div>
    </div>
  </div>
</template>

<script lang="ts" setup>
import { ref } from 'vue';
import { useState } from '@/apps/slideshowGame/useState';

defineProps({
  options: {
    type: Array<{ src: string; event: string; title: string }>,
    required: true,
  },
  title: {
    type: String,
    default: '',
  },
  subtitle: {
    type: String,
    default: '',
  },
  images: {
    type: Array as any,
    default: () => [],
  },
  src: {
    type: String,
    default:
      'https://res.cloudinary.com/dcodkxpej/image/upload/v1710756502/Decision%20making%20game/girls/Frame_36658_r6tnfd.svg',
  },
  border: {
    type: String,
    default: 'border-[#C83FFE]',
  },
});
const currentEvent = ref();
const { handleNext } = useState();

const goNext = event => {
  currentEvent.value = event;
  setTimeout(() => {
    handleNext(event);
  }, 1500);
};
</script>

<style scoped>
.active {
  transition: border-color 0.5s ease;
}
</style>
