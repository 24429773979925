import ActionType from '@/apps/slideshowGame/ActionType';

export const slide33 = {
  id: 'slide33_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      on: {
        [ActionType.EMOTION]: { target: '#slides.slide34' },
        [ActionType.INTUITIVE]: { target: '#slides.slide35' },
        [ActionType.PROCRASTINATION]: { target: '#slides.slide36' },
        [ActionType.RATIONAL]: { target: '#slides.slide37' },
      },
      meta: {
        id: 'fragment1',
        name: 'vs_choice',
        content: {
          images: [
            'https://res.cloudinary.com/dcodkxpej/image/upload/v1710665755/Decision%20making%20game/girls/Frame_36583_dvopvm.svg',
            'https://res.cloudinary.com/dcodkxpej/image/upload/v1710665758/Decision%20making%20game/girls/timothy-eberly-654Tcr0w568-unsplash_3_m9f08y.svg.svg',
          ],
          title:
            'מתלבטת- ללכת להופעה עם חברות או\n לפתוח דוכן ולהתרכז במכירות?              ',
          options: [
            {
              event: ActionType.EMOTION,
              title:
                'הולכת עם החברות. הדוכן יישאר היום סגור.                  ',
            },
            {
              event: ActionType.INTUITIVE,
              title:
                'מה הבעיה? גם וגם! לא יודעת איך, אבל אני אמצא פתרון.                    ',
            },
            {
              event: ActionType.PROCRASTINATION,
              title: 'מחכה לראות איזה חברות באות',
            },
            {
              event: ActionType.RATIONAL,
              title: 'יש מוסר עבודה. מוותרת על ההופעה.',
            },
          ],
        },
      },
    },
  },
  meta: {
    id: 'slide33',
    section: '65f2c85f8861cdcb662a47d4',
    display: {
      backgroundColor: '#FAD937',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-dark',
          title: 'הלהקה האהובה עליי- "הכפרות"\n מגיעה להופיע בפארק.',
        },
      },
    ],
  },
};
export default slide33;
