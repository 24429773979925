import ActionType from '@/apps/slideshowGame/ActionType';

export const slide8 = {
  id: 'slide8_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      on: {
        [ActionType.EMOTION]: { target: '#slides.slide9' },
        [ActionType.APPROVAL]: { target: '#slides.slide10' },
        [ActionType.RATIONAL]: { target: '#slides.slide11' },
      },
      meta: {
        id: 'fragment1',
        name: 'choice_image',
        type: 'question',
        content: {
          borderColor: '#F6652A',
          options: [
            {
              event: ActionType.RATIONAL,
              src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1711276836/Decision%20making%20game/girls/Rectangle_2044_1_izwk7k.png',
              title: 'בפארק בטח יהיו שם מלא אנשים',
            },
            {
              event: ActionType.EMOTION,
              src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1711276835/Decision%20making%20game/girls/Rectangle_2043_1_mz1dxa.png',
              title:
                'בגינה ליד הבית של סבתא, יש לי מלא זיכרונות ילדות שם. בטח יהיו שם מלא אנשים',
            },
            {
              event: ActionType.APPROVAL,
              src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1711276836/Decision%20making%20game/girls/Rectangle_2045_1_ivzxsy.png',
              title: 'לתת לשותף להחליט.',
            },
          ],
        },
      },
    },
  },
  meta: {
    id: 'slide8',
    section: '65f02174f18b670782f05ff4',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(162.24deg, #FF9900 3.25%, #FEBD59 51.89%, #FFCD14 102.77%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-white',
          title: 'איפה כדאי לפתוח את העסק?',
          subtitle: 'תלחץ על מה שאתה ממליץ לי ',
        },
      },
    ],
  },
};

export default slide8;
