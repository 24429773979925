import ActionType from '@/apps/slideshowGame/ActionType';
import { assign } from 'xstate';

export const slide26 = {
  id: 'slide26_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        1000: { target: '#slide26_fragments.fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'choice',
        content: {
          id: 'fragment1',
          event: ActionType.EMOTION,
          title: 'תני להם. לא הוגן להפלות              ',
        },
      },
    },
    fragment2: {
      after: {
        1000: { target: '#slide26_fragments.fragment3' },
      },
      meta: {
        id: 'fragment2',
        name: 'choice',
        content: {
          id: 'fragment2',
          event: ActionType.EMOTION,
          title: 'אל תהיי פראיירית. תסגרי את הדוכן ומהר              ',
        },
      },
    },
    fragment3: {
      after: {
        1000: { target: '#slide26_fragments.fragment4' },
      },
      meta: {
        id: 'fragment3',
        name: 'choice',
        content: {
          event: ActionType.SYSTEMATIC,
          title:
            'תתייעצי עם השותפה ותקבעו ביחד מה עושים במקרים כאלה                ',
        },
      },
    },
    fragment4: {
      after: {
        1000: { target: '#slide26_fragments.fragment5' },
      },
      meta: {
        id: 'fragment4',
        name: 'choice',
        content: {
          event: ActionType.RATIONAL,
          title: 'בחיים לא לתת. זה יהרוג לך את הרווחים',
        },
      },
    },
    fragment5: {
      on: {
        [ActionType.EMOTION]: [
          {
            target: '#slides.slide27',
            guard: ({ event }) => event.query === 'fragment1',
            actions: assign(({ context }) => {
              context.metadata = {
                count: 18,
                text: 'עם כל הבלאגן הזה לא\n הרווחנו כמעט כלום',
              };
            }),
          },
          {
            target: '#slides.slide28',
            guard: ({ event }) => event.query === 'fragment2',
            actions: assign(({ context }) => {
              context.metadata = {
                count: 24,
                text: 'עם כל הבלאגן הזה לא\n הרווחנו כמעט כלום',
              };
            }),
          },
        ],
        [ActionType.SYSTEMATIC]: {
          target: '#slides.slide29',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 90,
              text: 'בגלל כל הבלאגן מכרנו קצת פחות \n מהרגיל, אבל זה בסדר',
            };
          }),
        },
        [ActionType.RATIONAL]: {
          target: '#slides.slide30',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 20,
              text: 'עם כל הבלאגן הזה לא\n הרווחנו כמעט כלום',
            };
          }),
        },
        [ActionType.PROCRASTINATION]: {
          target: '#slides.slide31',
          actions: assign(({ context }) => {
            context.metadata = {
              count: 12,
              text: 'עם כל הבלאגן הזה לא\n הרווחנו כמעט כלום',
            };
          }),
        },
      },
      meta: {
        id: 'fragment5',
        name: 'choice',
        content: {
          event: ActionType.PROCRASTINATION,
          title: 'קחי נשימה. ותגידי להם שאת צריכה לחשוב על זה  ',
        },
      },
    },
  },
  meta: {
    id: 'slide26',
    section: '65f0239512ace1f2e53cc35f',
    display: {
      backgroundColor: '#C83FFE',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [],
    settings: {
      container: {
        backgroundColor: '#FFFFFF',
        borderRadius: '24px',
        header: {
          backgroundHeader: '#252526',
          title: 'הבלגן לא נגמר. \n מה אני אמורה לעשות? הצילו!!! ',
        },
      },
    },
  },
};
export default slide26;
