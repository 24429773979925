<template>
  <div
    ref="target"
    class="w-full flex items-center justify-center rounded-t-2xl rounded-bl-2xl shadow-dropdown-light p-3 gap-3 relative">
    <div
      v-if="showTextPress"
      class="absolute -top-20 -left-3 z-[999999] scale-75 max-h-md:scale-100">
      <div class="text-white font-simplerBold rotate-[328deg]">לחצו כאן</div>
      <img
        src="https://res.cloudinary.com/dcodkxpej/image/upload/v1710850191/Decision%20making%20game/girls/Group_36485_ewp3fy.svg"
        alt="img_arrow"/>
    </div>
    <div class="text-center">
      <span class="text-sm py-1 text-dark">
        {{ time }}
      </span>
    </div>
    <audio ref="player" :data-src="audioUrl" />
    <canvas ref="canvas" />
    <div
      class="flex items-center justify-center h-8 w-8 rounded-full cursor-pointer"
      :class="userGenderColor"
      @click="pausePlayHandle">
      <Play v-if="!playing" class="stroke-white fill-white h-3 w-4" />
      <Pause v-else class="stroke-white fill-white h-3 w-4" />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted, ref, watch } from 'vue';
import { Play, Pause } from 'lucide-vue-next';
import { useAVWaveform } from 'vue-audio-visual';
import { useMediaControls } from '@vueuse/core';
import { parseToMinutes } from '@/utils/helpers';
import { useState } from '@/apps/slideshowGame/useState';
import { useReveal } from '@/apps/slideshow/useReveal';

const { user }: { user: any } = useReveal();

interface IProps {
  src: string;
  event: string;
  position?: number;
}

const props = withDefaults(defineProps<IProps>(), {
  src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1708429520/Decision%20making%20game/boy/1.1_mf8mj4.mp3',
  event: 'WATCH_AUDIO',
  position: 0,
});
const { handleNext } = useState();
const player = ref<any>();
const canvas = ref<any>();
const audioUrl = ref<any>(props.src);
const playing = ref<any>(false);
const waveWidth = ref<number>(0);
const userGenderColor = computed(() =>
  user.value?.gender === 'male' ? 'bg-black' : 'bg-[#9747FF]',
);

const userGenderLine = computed(() =>
  user.value?.gender === 'male' ? 'black' : '#9747FF',
);

const { currentTime, ended } = useMediaControls(player, {
  src: audioUrl.value,
});
const target = ref();
const time = computed(() => parseToMinutes(currentTime.value));
const showTextPress = computed(
  () => !playing.value && currentTime.value === 0 && props.position === 1,
);

const pausePlayHandle = () => {
  playing.value = !playing.value;
  playing.value ? player.value.play() : player.value.pause();
};

const createWaveForm = audio => {
  useAVWaveform(player, canvas, {
    canvHeight: 30,
    canvWidth: waveWidth.value,
    playtimeSliderColor: '#000',
    src: audio,
    playtime: false,
    playedLineWidth: 2,
    noplayedLineColor: '#000',
    playedLineColor: userGenderLine.value,
  });
};

onMounted(() => {
  const observer = new ResizeObserver(entries => {
    for (let entry of entries) {
      waveWidth.value = entry.contentRect.width - 115;
      createWaveForm(audioUrl.value);
    }
  });
  if (target.value) {
    observer.observe(target.value);
  }
});

watch(ended, value => {
  if (value) {
    playing.value = false;
    handleNext(props.event);
  }
});
</script>
