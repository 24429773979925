export const slide65 = {
  id: 'slide65',
  on: {
    BACK: 'slide61',
  },
  meta: {
    id: 'slide65',
    display: {
      backgroundColor: '#FFD441',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'expanded_result',
        content: {
          titleColor: 'text-[#F6652A]',
          returnColor: 'text-black',
          option: {
            title: 'בטעם גומי-גם',
            subtitle: `
<p>
זה מתאים למי שמסתמך על שילוב בין החלטות ”ראש” המתבססות על נתונים לבין החלטות “בטן” המתבססות על אינטואיציה ותחושות.
</p>
<p>
חלק מההחלטות התאפיינו בשיקול דעת ובחינת היתרונות והחסרונות, תוך ביצוע על מנת לקבל את ההחלטה. גישה זו דורשת זמן והשקעת מאמץ רב על חשבון המהירות והיעילות ולעיתים עשויה להקשות ולסרבל את תהליך קבלת ההחלטות.
</p>
<p>
חלק מההחלטות התאפיינו דווקא בהסתמכות על הרגשות והתחושה המיידית שהאפשרויות השונות מעוררות. זה מאפשר קבלת החלטה מיידית וזריזה, אך מאחר ומקבל ההחלטה הוא המקור המרכזי למידע ולהחלטה, לא תמיד קיימת התייחסות לכלל הגורמים המשפיעים וקשה בדיעבד להסביר מדוע התקבלה החלטה זו.
</p>
<p>
 חשוב לדעת לאזן – לקבל החלטות מחושבות ומבוססות על מידע ונתונים, אך גם לדעת שלפעמים תחושת הבטן שלנו תוכל לעזור לנו לקבל את ההחלטה הנכונה
</p>`,
            type: 'middle',
          },
          event: 'BACK',
        },
      },
    ],
    settings: {
      container: {
        backgroundColor: '#F5F5F5',
        header: {
          backgroundHeader: '#FFFFFF',
          title: 'הקומונה של מיכאל',
          type: 'chat',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721803267/Decision%20making%20game/boy/hnracj4xhkrgkli1zoiw.svg',
        },
      },
    },
  },
};

export default slide65;
