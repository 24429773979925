export const slide34 = {
  on: {
    CLICK_NEXT: 'slide41',
  },
  meta: {
    id: 'slide34',
    section: '65f2cab0c06278d82f80b2e7',
    event: 'CLICK_NEXT',
    display: {
      backgroundColor: '',
      backgroundGradient: '',
      backgroundImage:
        'https://res.cloudinary.com/dcodkxpej/image/upload/v1721802001/Decision%20making%20game/boy/zkcvoxn8uzenuvffqhel.svg',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-white',
          title:
            'החברים יצאו מה זה פארשים. עד\nשהסכמתי לסגור את הדוכן, הם\n פתאום רצו ללכת לפיצה במקום\n ההופעה שממש רציתי לראות ',
        },
      },
    ],
  },
};
export default slide34;
