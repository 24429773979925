<template>
  <div class="flex w-full gap-2 xl:gap-4 items-end">
    <button
      class="relative flex gap-2 max-h-lg:gap-4 w-full items-center text-right shadow-dropdown-light px-3 max-h-lg:px-4 py-2 max-h-md:py-4"
      :class="[
        { [userGenderColor]: event === currentEvent },
        src ? 'rounded-t-2xl rounded-br-2xl' : 'rounded-full',
        { 'cursor-pointer': isLastFragment },
      ]"
      :disabled="disabled"
      @click="handleClick">
      <div v-if="icon" class="icon">{{ icon }}</div>
      <div
        v-if="extra"
        class="extra text-xs max-h-md:text-sm max-h-lg:text-base"
        v-html="extra"/>
      <div
        class="title text-xs max-h-md:text-sm max-h-lg:text-base"
        v-html="title"/>
    </button>
    <img
      v-if="src"
      :src="src"
      :alt="`${event}_img`"
      class="flex w-5 max-h-md:!w-7"/>
  </div>
</template>

<script lang="ts" setup>
import { computed, ref } from 'vue';
import { useState } from '@/apps/slideshowGame/useState';
import { useAnimation } from '@/apps/slideshowGame/animations/useAnimation';
import { useReveal } from '@/apps/slideshow/useReveal';

const { user }: { user: any } = useReveal();

const userGenderColor = computed(
  () =>
    (user.value?.gender === 'male' ? 'bg-[#FFE9DD]' : 'bg-purple-100') +
    ' active',
);

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  subtitle: {
    type: String,
    default: '',
  },
  toSlide: {
    type: String,
    default: '',
  },
  icon: {
    type: String,
    default: '',
  },
  event: {
    type: String as any,
    required: true,
  },
  src: {
    type: String,
    default: '',
  },
  id: {
    type: String,
    default: '',
  },
  type: {
    type: String,
    default: '',
  },
  extra: {
    type: String,
    default: '',
  },
});

const currentEvent = ref();
const { handleNext, isLastFragment, sendEvent } = useState();
const { isAnimating, startAnimation } = useAnimation();

const disabled = computed(() => {
  return !isLastFragment.value && !isAnimating.value;
});

const handleClick = () => {
  if (isLastFragment.value && !isAnimating.value) return goNext();
  return startAnimation(handleNext);
};
const goNext = () => {
  currentEvent.value = props.event;
  if (props.subtitle) {
    sendEvent(props.subtitle, props.toSlide);
  }

  setTimeout(() => {
    handleNext(props.event, props.id);
  }, 1000);
};
</script>

<style scoped>
.active {
  transition: background-color 0.3s ease;
}

.extra {
  @apply hidden;
}
</style>
