import SoldOut from '@/assets/decisionMakingGame/sold-out.svg';

export const slide40 = {
  on: {
    CLICK_NEXT: 'slide44',
  },
  meta: {
    id: 'slide40',
    section: '65f6bd2b260611eab1b11adb',
    event: 'CLICK_NEXT',
    display: {
      backgroundColor: '#C83FFE',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'image',
        type: 'content',
        content: {
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1710669079/Decision%20making%20game/girls/Group_36646_f8d5fs.svg',
          width: '276px',
          height: '276px',
        },
      },
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-[#FFBB0A]',
          title: 'כל הארטיקים נמכרו.              ',
          footer: 'איזה מזל שהלכתי עם האמת שלי\n ולא העברתי את הדוכן',
          colorFooter: 'text-white',
        },
      },
    ],
    elements: [
      {
        src: SoldOut,
        x: '45%',
        y: '-415px',
        zIndex: 100,
      },
    ],
  },
};
export default slide40;
