<template>
  <div
    v-if="shouldRender"
    class="inset-0 fixed top-0 right-0 left-0 bg-default"
    :style="{ 'z-index': 1000001 }">
    <div
      class="fixed top-0 right-0 left-0 z-[1009] h-full flex justify-center items-center overflow-y-none py-2 max-h-lg:py-10">
      <PresentationDeck />
    </div>
  </div>
</template>
<script setup lang="ts">
import { onMounted, onBeforeUnmount, ref } from 'vue';
import { useRouter } from 'vue-router';
import PresentationDeck from '@/apps/slideshowGame/PresentationDeck.vue';

const router = useRouter();
const shouldRender = ref(true);
onMounted(() => {
  const reloadDetected = sessionStorage.getItem('reloadDetected');
  if (reloadDetected) {
    sessionStorage.removeItem('reloadDetected');
    shouldRender.value = false;
    router.back();
  } else {
    window.addEventListener('beforeunload', handleBeforeUnload);
  }
});

const handleBeforeUnload = () => {
  sessionStorage.setItem('reloadDetected', 'true');
};

onBeforeUnmount(() => {
  window.removeEventListener('beforeunload', handleBeforeUnload);
});
</script>
