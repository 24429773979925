export const slide62 = {
  id: 'slide62_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      on: {
        CONTINUE: { target: '#slides.slide61' },
      },
      meta: {
        id: 'fragment1',
        name: 'vs_choice',
        content: {
          border: 'border-[#F6652A]',
          options: [
            {
              event: 'CONTINUE',
              title: 'להמשיך לקרוא',
            },
            {
              event: 'FINAL',
              title: 'כן, אני קראתי ',
            },
          ],
        },
      },
    },
  },
  meta: {
    id: 'slide62',
    display: {
      backgroundColor: '#FFCD14',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-black',
          title: 'כבר הולכים?',
          subtitle:
            'תוצאות השאלון לא נשמרות , במידה ולא סיימת\n לקרוא מומלץ להמשיך לקרוא',
        },
      },
    ],
  },
};

export default slide62;
