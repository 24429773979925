export const slide36 = {
  id: 'slide36_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      on: {
        WATCH_AUDIO: { target: '#slides.slide38' },
      },
      meta: {
        id: 'fragment1',
        name: 'audio_listener',
        content: {
          position: 1,
          event: 'WATCH_AUDIO',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1710419518/Decision%20making%20game/girls/5.3_%D7%90%D7%9D_%D7%A1%D7%99%D7%9E%D7%A0%D7%95_%D7%9E%D7%97%D7%9B%D7%94_%D7%9C%D7%A8%D7%90%D7%95%D7%AA_%D7%94%D7%97%D7%91%D7%A8%D7%95%D7%AA_%D7%9C%D7%90_%D7%A1%D7%92%D7%95%D7%A8%D7%95%D7%AA_%D7%A2%D7%9C_%D7%A2%D7%A6%D7%9E%D7%9F_hn1es4.mp3',
        },
      },
    },
  },
  meta: {
    id: 'slide36',
    section: '65f2cab0c06278d82f80b2e7',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(167deg, #BA60FE -15.54%, #D918FD 38.12%, #FBB2C8 94.91%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [],
    settings: {
      container: {
        backgroundColor: '#FFFFFF',
        display: 'end',
        heightPercentage: 0.77,
      },
    },
  },
};
export default slide36;
