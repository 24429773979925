<template>
  <div
    class="flex items-center justify-start w-full flex-col"
    :class="{
      [`shadow-dropdown-light rounded-t-2xl !w-75/100 max-h-lg:!w-70/100 ${height}`]:
        settingsContainer,
    }"
    :style="{
      background: settingsContainer?.backgroundColor,
      borderRadius: settingsContainer?.borderRadius,
      marginTop: settingsContainer?.marginTop,
      height:
        settingsContainer?.display === 'end'
          ? `${height * (settingsContainer.heightPercentage ?? 0.6)}px`
          : 'auto',
    }">
    <div
      v-if="settingsContainer?.header"
      class="h-16 max-h-md:h-20 max-h-lg:h-24 w-full rounded-t-2xl flex items-center justify-center"
      :style="{
        background: settingsContainer?.header.backgroundHeader,
      }">
      <div
        v-if="settingsContainer?.header?.type === 'chat'"
        class="flex w-full px-5 items-center justify-between my-2">
        <div class="flex items-center gap-1">
          <ChevronRight class="stroke-primary" />
          <img :src="settingsContainer?.header?.src" alt="chat_img" />
          <span class="font-simplerBold">
            {{ settingsContainer?.header.title }}
          </span>
        </div>
        <Phone class="stroke-primary" />
      </div>
      <div
        v-else
        class="text-white whitespace-pre-line font-anomaliaBold xl:text-lg max-h-md:text-md max-h-lg:text-lg">
        {{ settingsContainer?.header.title }}
      </div>
    </div>
    <slot name="content"></slot>
  </div>
</template>
<script setup lang="ts">
import { ChevronRight, Phone } from 'lucide-vue-next';
import { useWindowSize } from '@vueuse/core';

defineProps({
  settingsContainer: {
    type: Object,
    default: () => {},
  },
});

const { height } = useWindowSize();
</script>
