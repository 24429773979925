export const slide57 = {
  id: 'slide57_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      after: {
        6000: { target: '#slides.slide61' },
      },
      meta: {
        id: 'fragment1',
        name: 'lottie',
        type: 'content',
        content: {
          animationPath: 'final',
        },
      },
    },
  },
  meta: {
    id: 'slide57',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(170.15deg, #BA60FE -17.65%, #D918FD 102.97%, #FBB2C8 166.15%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'base_text',
        type: 'content',
        content: {
          color: 'text-white',
          title: 'איך את אוכלת את השלגון\n שלך ובאיזה טעם?',
          subtitle:
            'כלומר, מה דפוסי קבלת ההחלטות שבחרת במשחק זה, \n   תכף תראי את תוצאות התשובות שלך ',
        },
      },
    ],
  },
};
export default slide57;
