import { ref, computed } from 'vue';
import ActionType from '@/apps/slideshowGame/ActionType';

type CategoryCounts = {
  Head: number;
  Belly: number;
  Decision: number;
  Deliberation: number;
};

const counts = ref(
  Object.fromEntries(
    Object.values(ActionType).map(type => [type, 0]),
  ) as Record<string, number>,
);

const finalCategoryCounts = ref<CategoryCounts>({
  Head: 0,
  Belly: 0,
  Decision: 0,
  Deliberation: 0,
});

const finalDay = ref();

const categories = ref({
  Belly: [ActionType.EMOTION, ActionType.INTUITIVE, ActionType.IMPULSIVE],
  Head: [ActionType.RATIONAL, ActionType.SYSTEMATIC],
  Deliberation: [ActionType.APPROVAL, ActionType.PROCRASTINATION],
  Decision: [ActionType.INDEPENDENT, ActionType.DELIBERATION],
});

export function countEnumEvents(
  submissions: Record<string, any[]>,
): CategoryCounts {
  Object.keys(counts.value).forEach(key => {
    counts.value[key] = 0;
  });

  Object.values(submissions).flatMap((events: any[]) => {
    events.forEach(event => counts.value[event]++);
  });

  const categoryCounts = Object.fromEntries(
    Object.entries(categories.value).map(([category, actionTypes]) => [
      category,
      actionTypes.reduce((sum, type) => sum + counts.value[type], 0),
    ]),
  ) as CategoryCounts;

  return categoryCounts;
}

export function endGame(submissions: Record<string, any[]>) {
  finalCategoryCounts.value = countEnumEvents(submissions);
}

export function endDay(metadata) {
  finalDay.value = metadata;
}

export const computedFinalCategoryCounts = computed(
  () => finalCategoryCounts.value,
);

export const computedFinalDay = computed(() => finalDay.value);
