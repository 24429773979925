export const slide5 = {
  on: {
    CLICK_NEXT: 'slide8',
  },
  meta: {
    id: 'slide5',
    section: '65f02141fca6c45fd2c1a2df',
    display: {
      backgroundColor: '',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'video',
        type: 'content',
        content: {
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1721741486/Decision%20making%20game/boy/szfcx857xqp6lcmdhtym.mov',
          event: 'CLICK_NEXT',
        },
      },
    ],
  },
};
export default slide5;
