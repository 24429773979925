const urlParams = new URLSearchParams(window.location.search);
const gender = urlParams.get('gender');

const femaleSlides = import.meta.glob('./female/*.ts', {
  import: 'default',
  eager: true,
});
const femaleSlideModules = {};
for (const path in femaleSlides) {
  const moduleName = path.replace('./female/', '').replace('.ts', '');
  femaleSlideModules[moduleName] = femaleSlides[path] as any;
}

const maleSlides = import.meta.glob('./male/*.ts', {
  import: 'default',
  eager: true,
});
const maleSlideModules = {};
for (const path in maleSlides) {
  const moduleName = path.replace('./male/', '').replace('.ts', '');
  maleSlideModules[moduleName] = maleSlides[path] as any;
}

const slideModules = gender === 'male' ? maleSlideModules : femaleSlideModules;

export { slideModules };
