import SmallStar from '@/assets/decisionMakingGame/small-star.svg';

export const slide4 = {
  id: 'slide4_fragments',
  initial: 'fragment1',
  states: {
    fragment1: {
      on: {
        WATCH_AUDIO: { target: 'fragment2' },
      },
      meta: {
        id: 'fragment1',
        name: 'audio_listener',
        type: 'content',
        content: {
          position: 1,
          event: 'WATCH_AUDIO',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1716811738/Decision%20making%20game/girls/1.2_%D7%9C%D7%A7%D7%97%D7%AA%D7%99_%D7%91%D7%90%D7%9E%D7%AA_%D7%90%D7%AA_%D7%94%D7%96%D7%9E%D7%9F_%D7%9C%D7%99%D7%A9%D7%95%D7%9F_%D7%A2%D7%9C_%D7%96%D7%94_sywstj.mp3',
        },
      },
    },
    fragment2: {
      after: {
        1000: { target: '#slide4_fragments.fragment3' },
      },
      meta: {
        id: 'fragment2',
        name: 'choice',
        type: 'content',
        content: {
          event: 'CLICK_NOYA',
          icon: '❤️',
          title: 'נויה',
          id: '65d49da6a44cd68f7abef647',
        },
      },
    },
    fragment3: {
      on: {
        CLICK_YAEL: { target: '#slide4_fragments.fragment5' },
        CLICK_NOYA: { target: '#slide4_fragments.fragment4' },
      },
      meta: {
        id: 'fragment3',
        name: 'choice',
        type: 'fragment',
        content: {
          event: 'CLICK_YAEL',
          icon: '🧠',
          title: 'יעל',
          id: '65d49daada30dc7a78d6e520',
        },
      },
    },
    fragment4: {
      on: {
        CLICK_NEXT: { target: '#slides.slide8' },
      },
      meta: {
        id: 'fragment4',
        name: 'video',
        type: 'content',
        once: true,
        content: {
          event: 'CLICK_NEXT',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1709641481/Decision%20making%20game/girls/2.1_%D7%94%D7%A9%D7%95%D7%AA%D7%A4%D7%94_%D7%94%D7%97%D7%93%D7%A9%D7%94_%D7%A0%D7%95%D7%99%D7%94_gu99xr.mov',
          autoPlay: false,
          width: '100%',
          height: '100px',
          label: 'יום שלישי',
        },
      },
    },
    fragment5: {
      on: {
        CLICK_NEXT: { target: '#slides.slide8' },
      },
      meta: {
        id: 'fragment5',
        name: 'video',
        type: 'content',
        once: true,
        content: {
          event: 'CLICK_NEXT',
          src: 'https://res.cloudinary.com/dcodkxpej/video/upload/v1709641216/Decision%20making%20game/girls/2.2_%D7%94%D7%A9%D7%95%D7%AA%D7%A4%D7%94_%D7%94%D7%97%D7%93%D7%A9%D7%94_%D7%99%D7%A2%D7%9C_o9iaqz.mov',
          autoPlay: false,
          width: '100%',
          height: '100px',
          label: 'יום שלישי ',
        },
      },
    },
  },
  meta: {
    id: 'slide4',
    section: '65f02141fca6c45fd2c1a2df',
    display: {
      backgroundColor: '',
      backgroundGradient:
        'linear-gradient(167deg, #BA60FE 0%, #D918FD 43%, #FBB2C8 89%)',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [],
    elements: [
      {
        src: SmallStar,
        x: '99%',
        y: '-8%',
        zIndex: 100,
      },
    ],
    settings: {
      container: {
        backgroundColor: '#FFFFFF',
        display: 'end',
        heightPercentage: 0.77,
      },
    },
  },
};

export default slide4;
