export const slide67 = {
  id: 'slide67',
  on: {
    BACK: 'slide61',
  },
  meta: {
    id: 'slide67',
    display: {
      backgroundColor: '#FFD441',
      backgroundGradient: '',
      backgroundImage: '',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-no-repeat',
      backgroundOpacity: 1,
      backgroundVideo: '',
      transition: 'slide',
      transitionSpeed: 'default',
      backgroundTransition: 'fade',
    },
    content: [
      {
        name: 'expanded_result',
        content: {
          titleColor: 'text-[#F6652A]',
          returnColor: 'text-black',
          option: {
            title: 'אתה אוכל בביסים גדולים',
            subtitle: `<p>
זה מתאים לאנשים שיש להם רצון לבחור ולהכריע לגבי מה לעשות או כיצד לפעול.</p>
<p>
נראה שהבנת, שעדיף להחליט גם אם אין את כל המידע, כי זה עדיף מאשר לא לקבל החלטה בכלל.</p>
<p>
מאפיין זה מאפשר לחיות בשלום עם החלטות שבהסתכלות לאחור קיימת לגביהן חרטה או שהבחירה לא הוכיחה את עצמה, מתוך הבנה שאין החלטה נכונה אחת בלבד ושאפשר להיות מרוצים מהבחירה גם אם היא לא מושלמת.</p>
<p>
שים לב שלפעמים יש מצבים בהם דווקא נדרשת העמקה נוספת, ככה שלא תמיד הרצון לבחור ו״לחתוך״ משרת אותנו.</p>`,
            type: 'decision',
          },
          event: 'BACK',
        },
      },
    ],
    settings: {
      container: {
        backgroundColor: '#F5F5F5',
        header: {
          backgroundHeader: '#FFFFFF',
          title: 'הקומונה של מיכאל',
          type: 'chat',
          src: 'https://res.cloudinary.com/dcodkxpej/image/upload/v1721803267/Decision%20making%20game/boy/hnracj4xhkrgkli1zoiw.svg',
        },
      },
    },
  },
};

export default slide67;
